import { createApp } from 'vue';
import App from './App.vue'; // 创建一个父级组件来包含NavBar和Footer
import './assets/tailwind.css'; // Import Tailwind CSS



import { createRouter, createWebHistory } from 'vue-router';
import NavBarComponent from './components/NavBarComponent.vue';
import FooterComponent from './components/FooterComponent.vue';


import HomePage from './HomePage.vue';
import TeamPage from './TeamPage.vue';
import AboutPage from './AboutPage.vue';
import PrivacyPage from './PrivacyPage.vue';
import CareersPage from './CareersPage.vue';
import SamplingPage from './SamplingPage.vue';
import TermsPage from './TermsPage.vue';
import BlogPage from './BlogPage.vue';
import Project_ManagementPage from './Project_ManagementPage.vue';
import ContactPage from './ContactPage.vue';
import LoginPage from './LoginPage.vue';
import Enterprise_SolutionsPage from './Enterprise_SolutionsPage.vue';
import Create_AccountPage from './Create_AccountPage.vue';
import Personal_InformationPage from './Personal_InformationPage.vue';
import Thank_You_Form_FillPage from './Thank_You_Form_FillPage.vue';






// Blog类内容
import Preventing_Duplicate_Respondents from './blog/Preventing_Duplicate_Respondents.vue';
import Understanding_Fraud_Detection_Settings from './blog/Understanding_Fraud_Detection_Settings.vue';
import Online_Survey_Length from './blog/Online_Survey_Length.vue';
import Consumer_Insights from './blog/Consumer_Insights.vue';




import Index from './IndexComponents.vue';

// Initialize Google Analytics
function initializeAnalytics() {
  const gtagScript = document.createElement('script');
  gtagScript.async = true;
  gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-1Q1D1LRBJ9';
  document.head.appendChild(gtagScript);

  gtagScript.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', 'G-1Q1D1LRBJ9');
  };
}

// 定义路由
const routes = [
  {
    path: '/',
    component: Index, // 父组件
    redirect: '/',
    children: [
      {
        path: '/',
        component: HomePage,
        meta: { title: 'Top Strategies for Marketing and Consumer Insights | AgencyMR' }
      },
      {
        path: '/team',
        component: TeamPage,
        meta: { title: 'Our Team | Top Strategies for Marketing and Consumer Insights | AgencyMR' }
      },
      {
        path: '/about',
        component: AboutPage,
        meta: { title: 'About AgencyMR | Top Strategies for Marketing and Consumer Insights | AgencyMR' }
      },
      {
        path: '/sampling',
        component: SamplingPage,
        meta: { title: 'Sampling| Top Strategies for Marketing and Consumer Insights | AgencyMR' }
      },
      {
        path: '/careers',
        component: CareersPage,
        meta: { title: 'Careers| Top Strategies for Marketing and Consumer Insights | AgencyMR' }
      },
      {
        path: '/Contact',
        component: ContactPage,
        meta: { title: 'Contact us | Top Strategies for Marketing and Consumer Insights | AgencyMR' }
      },
      {
        path: '/privacy',
        component: PrivacyPage,
        meta: { title: 'Privacy Policy | Top Strategies for Marketing and Consumer Insights | AgencyMR' }
      },
      {
        path: '/terms',
        component: TermsPage,
        meta: { title: 'Terms of Use | Top Strategies for Marketing and Consumer Insights | AgencyMR' }
      },
      {
        path: '/Project_Management',
        component: Project_ManagementPage,
        meta: { title: 'Project_Management | Top Strategies for Marketing and Consumer Insights | AgencyMR' }
      },
      {
        path: '/Personal_Information',
        component: Personal_InformationPage,
        meta: { title: 'Do Not Sell My Personal Information | Top Strategies for Marketing and Consumer Insights | AgencyMR' }
      },
      {
        path: '/login',
        component: LoginPage,
        meta: { title: 'Login | Top Strategies for Marketing and Consumer Insights | AgencyMR' }
      },
      {
        path: '/create-account',
        component: Create_AccountPage,
        meta: { title: 'Create an Account | Top Strategies for Marketing and Consumer Insights | AgencyMR' }
      },
      {
        path: '/Enterprise_Solutions',
        component: Enterprise_SolutionsPage,
        meta: { title: 'Enterprise_Solutions | Top Strategies for Marketing and Consumer Insights | AgencyMR' }
      },
      {
        path: '/blog',
        component: BlogPage,
        meta: { title: 'Blog | Top Strategies for Marketing and Consumer Insights | AgencyMR' }
      },
      {
        path: '/Preventing_Duplicate_Respondents',
        component: Preventing_Duplicate_Respondents,
        meta: { title: 'Preventing_Duplicate_Respondents | Top Strategies for Marketing and Consumer Insights | AgencyMR' }
      },
      {
        path: '/Understanding_Fraud_Detection_Settings',
        component: Understanding_Fraud_Detection_Settings,
        meta: { title: 'Understanding_Fraud_Detection_Settings | Top Strategies for Marketing and Consumer Insights | AgencyMR' }
      },
      {
        path: '/Thank_You_Form_Fill',
        name: 'Thank_You_Form_Fill', 
        component: Thank_You_Form_FillPage,
        meta: { title: 'Thank_You_Form_Fill | Top Strategies for Marketing and Consumer Insights | AgencyMR' }
      },
      {
        path: '/Online_Survey_Length',
        component: Online_Survey_Length,
        meta: { title: 'Online_Survey_Length | Top Strategies for Marketing and Consumer Insights | AgencyMR' }
      },
      {
        path: '/Consumer_Insights',
        component: Consumer_Insights,
        meta: { title: 'Consumer_Insights | Top Strategies for Marketing and Consumer Insights | AgencyMR' }
      },
    ],
  },
  // {
  //   path: '/login',
  //   component: LoginPage,
  //   meta: { title: 'Login | Top Strategies for Marketing and Consumer Insights | AgencyMR', hideNavBarAndFooter: true }
  // },
];

// 创建路由器实例
const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 添加路由守卫来设置页面标题
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = 'Top Strategies for Marketing and Consumer Insights | AgencyMR';
  }
  next();
});

// 创建 Vue 实例并挂载
const app = createApp(App);
app.component('NavBarComponent', NavBarComponent);
app.component('FooterComponent', FooterComponent);
// 全局滚动实例
app.directive('scroll-reveal', {
  mounted(el) {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          el.classList.add('opacity-100')
          observer.unobserve(el)
        }
      },
      { threshold: 0.1 }
    )
    observer.observe(el)
  }
})



app.use(router);
app.mount('#app');

// Call Google Analytics initialization function
initializeAnalytics();

