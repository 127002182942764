<template>
  
    <NavBarComponent />
    <router-view />
    <FooterComponent />
  </template>
  
  <script>
  
  </script>
  
  <style>
  
  </style>