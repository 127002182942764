<template>
  
 
  <section>
  <div class="bg-white py-32 sm:py-64">
    <div class="mx-auto max-w-7xl px-6 text-left lg:px-8">
      <div class="mx-auto">
        <h1 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-6xl ">Terms of Use</h1>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif"><b class="text-3xl">1.1</b> AgencyMR will comply with all relevant policies and codes of conduct of the Customer. AgencyMR shall provide the Services with at least the same degree of quality and timeliness customarily exercised by the Client in connection with its own business operations. </p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900  mt-12 ">2.RESTRICTIONS AND RESPONSIBILITIES</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif"><b class="text-3xl">2.1</b> The Customer will not, directly or indirectly, reverse engineer, decompile, disassemble, or otherwise attempt to discover the source code, object code or underlying structure, ideas, know-how or algorithms relevant to the Services or any software, documentation or data related to the Services (“Software”); modify, translate, or create derivative works based on the Services or any Software (except to the extent expressly permitted by AgencyMR or authorized within the Services); use the Services or any Software for timesharing or service bureau purposes or otherwise for the benefit of a third; or remove any proprietary notices or labels.</p>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif"><b class="text-3xl">2.2</b> The Customer represents, covenants, and warrants that the Customer will use the Services only in compliance with AgencyMR’s standard published policies then in effect (the “Policy”) and all applicable laws and regulations. The Customer hereby agrees to indemnify and hold harmless AgencyMR against any damages, losses, liabilities, settlements, and expenses (including without limitation costs and attorneys’ fees) in connection with any claim or action that arises from an alleged violation of the foregoing or otherwise from the Customer’s use of Services. Although AgencyMR has no obligation to monitor the Customer’s use of the Services, AgencyMR may do so and may prohibit any use of the Services it believes may be (or alleged to be) in violation of the foregoing.</p>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif"><b class="text-3xl">2.3</b> The Customer shall indemnify, defend, and hold harmless the AgencyMR, contractors and third-party suppliers, and each of its and their respective officers, directors, employees, agents, permitted successors, and permitted assigns from and against, all losses suffered or incurred by it or them.</p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900  mt-12 ">3.PAYMENT OF FEES</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif"><b class="text-3xl">3.1</b> All invoices are due within thirty (30) days of the invoice date. The Customer agrees to reimburse AgencyMR for all reasonable out-of-pocket expenses as set forth in the relevant Statement of Work that are necessarily incurred by AgencyMR in its performance of the Services or creation of Deliverables.</p>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif"><b class="text-3xl">3.2</b> All amounts outstanding beyond thirty (30) days of the invoice date will be subject to a finance charge of 1.5% per month. The Customer agrees to pay all taxes imposed by any jurisdiction or governmental entity in connection with the transactions contemplated by this Agreement, whether sales, use, excise or otherwise, except those on AgencyMR’s income and personal property.</p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900  mt-12 ">4.TERM AND TERMINATION</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif"><b class="text-3xl">4.1</b> Subject to earlier termination as provided below, this Agreement is for each SOW.</p>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif"><b class="text-3xl">4.2</b> In addition to any other remedies it may have, either party may also terminate this Agreement upon thirty (30) days’ notice (or without notice in the case of nonpayment), if the other party materially breaches any of the terms or conditions of this Agreement. The Customer will pay in full for the Services up to and including the last day on which the Services are provided. Upon any termination, all sections of this Agreement, which can by their nature, should survive termination will survive termination, including, without limitation, accrued rights to payment, confidentiality obligations, warranty disclaimers, and limitations of liability.</p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900  mt-12 ">5.CONFIDENTIALITY</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif"><b class="text-3xl">5.1</b> Each party (the “Receiving Party”) understands that the other party (the “Disclosing Party”) has disclosed or may disclose business, technical or financial information relating to the Disclosing Party’s business (hereinafter referred to as “Proprietary Information” of the Disclosing Party). Proprietary Information of AgencyMR includes non-public information regarding features, functionality, and performance of the Service. Proprietary Information of the Customer includes survey instruments, inputs (such as images and algorithms), and other non-public information or documents provided by the Customer to AgencyMR to enable the provision of the Services (“Customer Data”). The Receiving Party agrees: (i) to take reasonable precautions to protect such Proprietary Information, and (ii) not to use (except in performance of the Services or as otherwise permitted herein) or divulge to any third person any such Proprietary Information.</p>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif"><b class="text-3xl">5.2</b> The Customer shall own all rights, titles, and interests in and to the Customer Data. AgencyMR shall own and retain all rights, titles, and interests in and to (a) the Services and Software, all improvements, enhancements, or modifications thereto, (b) any software, applications, inventions, or other technology developed in connection with support, and (c) all intellectual property rights related to any of the foregoing.</p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900  mt-12 ">6.INTELLECTUAL PROPERTY</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif"><b class="text-3xl">6.1</b> Ownership. As used in this Agreement, “Deliverables” shall mean all work products prepared and delivered by AgencyMR during the course of Services to the Customer pursuant to this Agreement. Work Product as defined herein expressly excludes AgencyMR IP, including the AgencyMR Platform. The Customer shall own all rights, titles, and interests, including the copyright, in the Deliverables and AgencyMR shall assign all rights, titles, and interests in and to such rights, including any and all copyrights of the Deliverables to the Customer.</p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900  mt-12 ">7.MUTUAL WARRANTY AND DISCLAIMER</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif"><b class="text-3xl">7.1</b> AgencyMR shall use reasonable efforts consistent with prevailing industry standards to maintain the Services in a manner that minimizes errors and interruptions in the Services. Services may be temporarily unavailable for scheduled maintenance or for unscheduled emergency maintenance, either by AgencyMR or by third-party providers, or because of other causes beyond AgencyMR’s reasonable control, but AgencyMR shall use reasonable efforts to provide advance notice in writing or by e-mail of any scheduled service disruption. However, AgencyMR does not warrant that the Services will be uninterrupted or error-free; nor does it make any warranty as to the results that may be obtained from the use of the Services. EXCEPT AS EXPRESSLY SET FORTH IN THIS SECTION, THE SERVICES ARE PROVIDED “AS IS” AND AgencyMR DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.</p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900  mt-12 ">8.MUTUAL INDEMNIFICATION</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif"><b class="text-3xl">8.1</b> AgencyMR shall hold the Customer harmless from liability to third parties resulting from infringement by the Service of any United States patent or any copyright or misappropriation of any trade secret, provided AgencyMR is promptly notified of any and all threats, claims, and proceedings related thereto and given reasonable assistance and the opportunity to assume sole control over defense and settlement; AgencyMR will not be responsible for any settlement it does not approve in writing.</p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900  mt-12 ">9.LIMITATION OF LIABILITY</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif"><b class="text-3xl">9.1</b> IN NO EVENT SHALL AgencyMR BE LIABLE FOR ANY INDIRECT, SPECIAL, CONSEQUENTIAL, INCIDENTAL, PUNITIVE OR OTHER DAMAGES RELATED TO THIS AGREEMENT (INCLUDING, WITHOUT LIMITATION, LOSS OF DATA, LOSS OF INCOME, LOSS OF OPPORTUNITY, LOST PROFITS, COSTS OF RECOVERY OR ANY OTHER DAMAGES), HOWEVER CAUSED AND BASED ON ANY THEORY OF LIABILITY, AND WHETHER OR NOT FOR BREACH OF CONTRACT, NEGLIGENCE, INDEMNIFICATION OR OTHERWISE, AND WHETHER OR NOT AgencyMR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. AgencyMR’S LIABILITY HEREUNDER IS LIMITED TO THE AMOUNT OF FEES PAID TO AgencyMR UNDER THE APPLICABLE STATEMENT OF WORK DURING THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEDING THE ACTION THAT GAVE RISE TO THE LIABILITY.</p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900  mt-12 ">10.MISCELLANEOUS</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif"><b class="text-3xl">10.1</b> Relationship: No agency, partnership, joint venture, or employment is created as a result of this Agreement and the Customer does not have any authority of any kind to bind AgencyMR in any respect whatsoever.</p>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif"><b class="text-3xl">10.2</b> Force Majeure: Other than with respect to the Customer’s payment obligations hereunder, neither party shall be deemed in default of the Agreement to the extent that performance of its obligations or attempts to cure any breach are delayed, restricted or prevented by reason of any act of God, fire, natural disaster, act of government, strikes or labor disputes, inability to provide raw materials, power or supplies or any other similar act or condition beyond the reasonable control of such party.</p>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif"><b class="text-3xl">10.3</b> Insurance: During the term of this Agreement, AgencyMR will carry, at its sole cost and expense and with a commercial insurance carrier, insurance with coverage(s) and limits that are reasonable and customary within the Customer’s and AgencyMR’s respective industry, including Cyber Liability insurance, for the obligations and undertakings made in connection with this Agreement.</p>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif"><b class="text-3xl">10.4</b> Notices: All notices under this Agreement will be in writing and will be deemed to have been duly given when received.</p>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif"><b class="text-3xl">10.5</b> Severability: If any provision of this Agreement is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that this Agreement will otherwise remain in full force and effect and enforceable.</p>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif"><b class="text-3xl">10.6</b> Governing Law and Jurisdiction: This Agreement shall be governed by the laws of the State of California without regard to its conflict of laws provisions.</p>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif"><b class="text-3xl">10.7</b> Entire Agreement: This Agreement is not assignable, transferable or sublicensable by the Customer except with AgencyMR’s prior written consent. AgencyMR may transfer and assign any of its rights and obligations under this Agreement without consent. This Agreement is the complete and exclusive statement of the mutual understanding of the parties and supersedes and cancels all previous written and oral agreements, communications, and other understandings relating to the subject matter of this Agreement, and all waivers and modifications must be in a writing signed by both parties, except as otherwise provided herein.</p>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif"><b class="text-3xl">10.8</b> Notices. All notices relating to this Agreement, including that of data breaches, shall be sent in writing to info@agencymr.com .</p>
        <h2 class="text-xl font-bold tracking-tight text-gray-900  mt-12 ">Last updated: 07-Mar-2023</h2>
      </div>
  
    </div>
  </div>
  </section>
  <router-view />

</template>

<script>

</script>

<style>

</style>