
<template>


<section>
  <div class="bg-gray-50 py-24 sm:py-32">
    <div class="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
      <div class="mx-auto max-w-7xl px-6 py-32 text-center sm:py-12 lg:px-8">
      <p class="text-base/8 font-semibold text-black">Thank You</p>
      <h1 class="mt-4 text-balance text-5xl font-semibold tracking-tight text-black sm:text-7xl">A member of our team will be in touch soon.</h1>
      <p class="mt-6 text-pretty text-lg font-medium text-black/70 sm:text-xl/8">While you wait, check out the latest insights in market research by visiting our Resource Center.</p>
      <div class="mt-10 flex justify-center">
        <a href="/blog" class="text-sm/7 font-semibold text-black"><span aria-hidden="true">&larr;</span> Go to Blog</a>
      </div>
    </div>
        <div class="mt-10 grid grid-cols-1 gap-4 sm:mt-16 lg:grid-cols-6  ">
        
        <div class="relative lg:col-span-2">
          <div class="absolute inset-px rounded-lg bg-white lg:rounded-bl-[2rem]" />
          <div class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-b-[calc(2rem+1px)] lg:rounded-br-[calc(2rem+1px)]">
            <img class="h-80 object-cover object-center" src="./assets/images/a8.jpg" alt="" />
            <div class="p-10 pt-4">
     
              <p class="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">B2C</p>
              <p class="mt-2 max-w-lg text-sm/6 text-gray-600">You can directly connect with the highest quality end consumers worldwide through our verified survey suppliers, gaining insights into their purchasing habits, brand preferences, and consumer psychology to capture market dynamics.</p>
            </div>
          </div>
          <div class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-bl-[2rem]" />
        </div>
        <div class="relative lg:col-span-2">
          <div class="absolute inset-px rounded-lg bg-white" />
          <div class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)]">
            <img class="h-80 object-cover object-center" src="./assets/images/a9.jpg" alt="" />
            <div class="p-10 pt-4">
    
              <p class="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">B2B</p>
              <p class="mt-2 max-w-lg text-sm/6 text-gray-600">Similarly, through our platform, you can reach decision-makers and executives across various industries, allowing your market research to delve into business needs, trends, and challenges. Agencymr can provide you with precise B2B audiences.</p>
            </div>
          </div>
          <div class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5" />
        </div>
        <div class="relative lg:col-span-2">
          <div class="absolute inset-px rounded-lg bg-white max-lg:rounded-b-[2rem] lg:rounded-br-[2rem]" />
          <div class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-b-[calc(2rem+1px)] lg:rounded-br-[calc(2rem+1px)]">
            <img class="h-80 object-cover object-center" src="./assets/images/a7.jpg" alt="" />
            <div class="p-10 pt-4">

              <p class="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">Healthcare</p>
              <p class="mt-2 max-w-lg text-sm/6 text-gray-600">If your research involves the healthcare field, our sampling services can help you connect with doctors, nurses, patients, and other healthcare professionals.</p>
            </div>
          </div>
          <div class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-b-[2rem] lg:rounded-br-[2rem]" />
        </div>
      </div>

    </div>
  </div>
</section>

</template>


  <style>
  
  </style>