
<template>
  <div class="bg-gray-50">
    <div class="mx-auto max-w-7xl py-24 sm:px-2 sm:py-32 lg:px-4">
      <div class="mx-auto max-w-2xl px-4 lg:max-w-none">
        <div class="grid grid-cols-1 items-center gap-x-16 gap-y-10 lg:grid-cols-2">
          <div>
            <h2 class="text-4xl font-bold tracking-tight text-gray-900" style="color: #0D2254;">Why Join AgencyMR</h2>
            <p class="mt-4 text-gray-500 text-md leading-8 text-xl">At AgencyMR, we don’t just conduct market research; we are shaping the future. As a company committed to simplifying the research process, we firmly believe that an excellent team is the core driving force behind innovation and success. By joining us, you will become part of a vibrant and creative team, working together to achieve excellence.</p>
          </div>
          <div class="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg bg-gray-100">
            <img src="@/assets/images/a1.jpg" alt="" class="object-cover object-center" />
          </div>
        </div>

      <div class="mx-auto max-w-7xl py-24 sm:px-2 sm:py-32 lg:px-4">
      <div class="mx-auto max-w-2xl px-4 lg:max-w-none">
        <div class="max-w-8xl grid grid-cols-1 items-center gap-x-16 gap-y-10 lg:grid-cols-2">
          <div>
            <h2 class="text-4xl font-bold tracking-tight text-gray-900" style="color: #0D2254;">Cutting-Edge Work Environment</h2>
            <p class="mt-4 text-gray-500 text-md leading-8 text-xl">At AgencyMR, you will have access to the most advanced artificial intelligence and data analysis tools to enhance your research capabilities. We partner with the world’s top data providers to ensure you have the best resources at your disposal to achieve your career goals.</p>
          </div>


          <div class="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg bg-gray-100 w-4/5">
            <img src="@/assets/images/a2.jpg" alt="" class="object-cover object-center" />
          </div>
        </div>
      </div>
      </div>


      <div class="mx-auto max-w-7xl py-24 sm:px-2 sm:py-6 lg:px-4">
      <div class="mx-auto max-w-2xl px-4 lg:max-w-none">
        <div class="max-w-8xl grid grid-cols-1 items-center gap-x-16 gap-y-10 lg:grid-cols-2">
          <div class="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg bg-gray-100 w-4/5">
            <img src="@/assets/images/a3.jpg" alt="" class="object-cover object-center" />
          </div>
          <div>
            <h2 class="text-4xl font-bold tracking-tight text-gray-900" style="color: #0D2254;">Rich Career Development Opportunities</h2>
            <p class="mt-4 text-gray-500 text-md leading-8 text-xl">We provide our employees with a wide range of learning and growth opportunities. Whether you’re an entry-level professional or a seasoned expert, AgencyMR will support and train you to help you continuously progress in the field of market research.</p>
          </div>
        </div>
      </div>
      </div>

      <div class="mx-auto max-w-7xl py-24 sm:px-2 sm:py-32 lg:px-4">
      <div class="mx-auto max-w-2xl px-4 lg:max-w-none">
        <div class="max-w-8xl grid grid-cols-1 items-center gap-x-16 gap-y-10 lg:grid-cols-2">
          <div>
            <h2 class="text-4xl font-bold tracking-tight text-gray-900" style="color: #0D2254;">Positive Team Culture</h2>
            <p class="mt-4 text-gray-500 text-md leading-8 text-xl">Our team consists of top researchers and industry experts from around the world, working collaboratively and sharing knowledge and experiences. We encourage open communication and collaboration, ensuring that every member's voice is heard. Join AgencyMR and work alongside like-minded colleagues to drive innovation in market research.</p>
          </div>


          <div class="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg bg-gray-100 w-4/5">
            <img src="@/assets/images/a4.jpg" alt="" class="object-cover object-center" />
          </div>
        </div>
      </div>
      </div>


      <div class="mx-auto max-w-7xl py-24 sm:px-2 sm:py-6 lg:px-4">
      <div class="mx-auto max-w-2xl px-4 lg:max-w-none">
        <div class="max-w-8xl grid grid-cols-1 items-center gap-x-16 gap-y-10 lg:grid-cols-2">
          <div class="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg bg-gray-100 w-4/5">
            <img src="@/assets/images/a5.jpg" alt="" class="object-cover object-center" />
          </div>
          <div>
            <h2 class="text-4xl font-bold tracking-tight text-gray-900" style="color: #0D2254;">Impact and Sense of Achievement</h2>
            <p class="mt-4 text-gray-500 text-md leading-8 text-xl">At AgencyMR, your work will directly influence global business decisions. Our clients have generated over $12 million in revenue through our research, and you will be part of this successful journey, experiencing a sense of accomplishment and value.</p>
          </div>
        </div>
      </div>
      </div>  

      <div class="mx-auto max-w-7xl py-24 sm:px-2 sm:py-32 lg:px-4">
      <div class="mx-auto max-w-2xl px-4 lg:max-w-none">
        <div class="max-w-8xl grid grid-cols-1 items-center gap-x-16 gap-y-10 lg:grid-cols-2">
          <div>
            <h2 class="text-4xl font-bold tracking-tight text-gray-900" style="color: #0D2254;">Flexibility and Support</h2>
            <p class="mt-4 text-gray-500 text-md leading-8 text-xl">We understand that balancing work and life is crucial for success. AgencyMR offers flexible working arrangements and support to help you find the best balance in your career and personal life.</p>
          </div>


          <div class="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg bg-gray-100 w-4/5">
            <img src="@/assets/images/a6.jpg" alt="" class="object-cover object-center" />
          </div>
        </div>
      </div>
      </div>

      <div class="text-center">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Apply Now</h2>
        <p class="mt-4 text-gray-500 text-md leading-8 text-xl">If you desire to work in an environment filled with challenges and opportunities, we invite you to apply to join AgencyMR. Let’s work together to provide exceptional market research solutions for global businesses!</p>
    
        <button
  type="button"
  class="flex justify-center gap-2 items-center mx-auto shadow-xl text-lg bg-gray-50 backdrop-blur-md lg:font-semibold isolation-auto border-gray-50 before:absolute before:w-full before:transition-all before:duration-700 before:hover:w-full before:-left-full before:hover:left-0 before:rounded-full before:bg-[#0071BC] hover:text-gray-50 before:-z-10 before:aspect-square before:hover:scale-150 before:hover:duration-700 relative z-10 px-4 py-2 overflow-hidden border-2 rounded-full group"
  @click="goToLink"
>
  Explore
  <svg
    class="w-8 h-8 justify-end group-hover:rotate-90 group-hover:bg-gray-50 text-gray-50 ease-linear duration-300 rounded-full border border-gray-700 group-hover:border-none p-2 rotate-45"
    viewBox="0 0 16 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 18C7 18.5523 7.44772 19 8 19C8.55228 19 9 18.5523 9 18H7ZM8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893L0.928932 6.65685C0.538408 7.04738 0.538408 7.68054 0.928932 8.07107C1.31946 8.46159 1.95262 8.46159 2.34315 8.07107L8 2.41421L13.6569 8.07107C14.0474 8.46159 14.6805 8.46159 15.0711 8.07107C15.4616 7.68054 15.4616 7.04738 15.0711 6.65685L8.70711 0.292893ZM9 18L9 1H7L7 18H9Z"
      class="fill-gray-800 group-hover:fill-gray-800"
    ></path>
  </svg>
</button>


      </div>
      </div>
    </div>
  </div>


    <div class="bg-gray-900 pb-20 sm:pb-24 xl:pb-0">
      <div class="mx-auto flex max-w-7xl flex-col items-center gap-x-8 gap-y-10 px-6 sm:gap-y-8 lg:px-8 xl:flex-row xl:items-stretch">
        <div class="-mt-8 w-full max-w-2xl xl:-mb-8 xl:w-96 xl:flex-none">
          <div class="relative aspect-[2/1] h-full md:-mx-8 xl:mx-0 xl:aspect-auto">
            <img class="absolute inset-0 h-full w-full rounded-2xl bg-gray-800 object-cover shadow-2xl" src="@/assets/images/a13.jpg" alt="" />
          </div>
        </div>
        <div class="w-full max-w-2xl xl:max-w-none xl:flex-auto xl:px-16 xl:py-24">
          <figure class="relative isolate pt-6 sm:pt-12">
            <svg viewBox="0 0 162 128" fill="none" aria-hidden="true" class="absolute left-0 top-0 -z-10 h-32 stroke-white/20">
              <path id="b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb" d="M65.5697 118.507L65.8918 118.89C68.9503 116.314 71.367 113.253 73.1386 109.71C74.9162 106.155 75.8027 102.28 75.8027 98.0919C75.8027 94.237 75.16 90.6155 73.8708 87.2314C72.5851 83.8565 70.8137 80.9533 68.553 78.5292C66.4529 76.1079 63.9476 74.2482 61.0407 72.9536C58.2795 71.4949 55.276 70.767 52.0386 70.767C48.9935 70.767 46.4686 71.1668 44.4872 71.9924L44.4799 71.9955L44.4726 71.9988C42.7101 72.7999 41.1035 73.6831 39.6544 74.6492C38.2407 75.5916 36.8279 76.455 35.4159 77.2394L35.4047 77.2457L35.3938 77.2525C34.2318 77.9787 32.6713 78.3634 30.6736 78.3634C29.0405 78.3634 27.5131 77.2868 26.1274 74.8257C24.7483 72.2185 24.0519 69.2166 24.0519 65.8071C24.0519 60.0311 25.3782 54.4081 28.0373 48.9335C30.703 43.4454 34.3114 38.345 38.8667 33.6325C43.5812 28.761 49.0045 24.5159 55.1389 20.8979C60.1667 18.0071 65.4966 15.6179 71.1291 13.7305C73.8626 12.8145 75.8027 10.2968 75.8027 7.38572C75.8027 3.6497 72.6341 0.62247 68.8814 1.1527C61.1635 2.2432 53.7398 4.41426 46.6119 7.66522C37.5369 11.6459 29.5729 17.0612 22.7236 23.9105C16.0322 30.6019 10.618 38.4859 6.47981 47.558L6.47976 47.558L6.47682 47.5647C2.4901 56.6544 0.5 66.6148 0.5 77.4391C0.5 84.2996 1.61702 90.7679 3.85425 96.8404L3.8558 96.8445C6.08991 102.749 9.12394 108.02 12.959 112.654L12.959 112.654L12.9646 112.661C16.8027 117.138 21.2829 120.739 26.4034 123.459L26.4033 123.459L26.4144 123.465C31.5505 126.033 37.0873 127.316 43.0178 127.316C47.5035 127.316 51.6783 126.595 55.5376 125.148L55.5376 125.148L55.5477 125.144C59.5516 123.542 63.0052 121.456 65.9019 118.881L65.5697 118.507Z" />
              <use href="#b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb" x="86" />
            </svg>
            <blockquote class="text-xl font-semibold leading-8 text-white sm:text-2xl sm:leading-9">
              <p>By joining AgencyMR, you are not just looking for a job; you are participating in a mission. We are dedicated to enhancing the accuracy and reach of market research by simplifying the research process, thereby creating value for global businesses. Our team is the embodiment of this mission, and we look forward to your contribution as we collectively drive the future of market research.</p>
            </blockquote>
            <figcaption class="mt-8 text-base">
              <div class="font-semibold text-white">Andrew Smith</div>
              <div class="mt-1 text-gray-400">CEO of AgencyMR</div>
            </figcaption>
          </figure>
        </div>
      </div>
    </div>

</template>

<script>
export default {
  methods: {
    goToLink() {
      window.location.href = '/contact';  
    }
  }
}
</script>
