<template>
  
  <header ref="header" :class="headerClass" class="fixed top-0 left-0 w-full  z-10 transition-shadow duration-300">
   <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
     <div class="flex lg:flex-1">
       <a href="/" class="-m-1.5 p-1.5">
         <span class="sr-only">AgencyMR</span>
         <img class="h-16 w-auto" src="@/assets/images/logo.svg" alt="" />
       </a>
     </div>
     <div class="flex lg:hidden">
       <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = true">
         <span class="sr-only"></span>
         <Bars3Icon class="h-6 w-6" aria-hidden="true" />
       </button>
     </div>
     <PopoverGroup class="hidden lg:flex lg:gap-x-12 space-x-4">
     

      <Popover class="relative">
          <PopoverButton class="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
            <span>Services</span>
            <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
          </PopoverButton>
          <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
            <PopoverPanel class="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
              <div class="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                <div class="p-4">
                  <div v-for="item in solutions" :key="item.name" class="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                    <div class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                      <component :is="item.icon" class="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                    </div>
                    <div>
                      <a :href="item.href" class="font-semibold text-gray-900">
                        {{ item.name }}
                        <span class="absolute inset-0" />
                      </a>
                      <p class="mt-1 text-gray-600">{{ item.description }}</p>
                    </div>
                  </div>
                </div>
                <div class="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                  <a v-for="item in callsToAction" :key="item.name" :href="item.href" class="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100">
                    <component :is="item.icon" class="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                    {{ item.name }}
                  </a>
                </div>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>
       <Popover>
        
         <PopoverButton class="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900" trigger="hover">
           Resources
           <ChevronDownIcon class="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
         </PopoverButton>

         <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 -translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 -translate-y-1">
           <PopoverPanel class="absolute inset-x-0 top-0 -z-10 bg-white pt-14 shadow-lg ring-1 ring-gray-900/5">
             <div class="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-6 py-10 lg:grid-cols-2 lg:px-8">
               <div class="grid grid-cols-2 gap-x-6 sm:gap-x-8">
                 <div>
                   <h3 class="text-sm font-medium leading-6 text-gray-500">Company</h3>
                   <div class="mt-6 flow-root">
                     <div class="-my-2">
                       <a v-for="item in engagement" :key="item.name" :href="item.href" class="flex gap-x-4 py-2 text-sm font-semibold leading-6 text-gray-900">
                         <component :is="item.icon" class="h-6 w-6 flex-none text-gray-400" aria-hidden="true" />
                         {{ item.name }}
                       </a>
                     </div>
                   </div>
                 </div>
                 <div>
                   <h3 class="text-sm font-medium leading-6 text-gray-500">Resources</h3>
                   <div class="mt-6 flow-root">
                     <div class="-my-2">
                       <a v-for="item in resources" :key="item.name" :href="item.href" class="flex gap-x-4 py-2 text-sm font-semibold leading-6 text-gray-900">
                         <component :is="item.icon" class="h-6 w-6 flex-none text-gray-400" aria-hidden="true" />
                         {{ item.name }}
                       </a>
                     </div>
                   </div>
                 </div>
               </div>
               <div class="grid grid-cols-1 gap-10 sm:gap-8 lg:grid-cols-2">
                 <h3 class="sr-only">Recent posts</h3>
                 <article v-for="post in recentPosts" :key="post.id" class="relative isolate flex max-w-2xl flex-col gap-x-8 gap-y-6 sm:flex-row sm:items-start lg:flex-col lg:items-stretch">
                   <div class="relative flex-none">
                     <img class="aspect-[2/1] w-full rounded-lg bg-gray-100 object-cover sm:aspect-[16/9] sm:h-32 lg:h-auto" :src="post.imageUrl" alt="" />
                     <div class="absolute inset-0 rounded-lg ring-1 ring-inset ring-gray-900/10" />
                   </div>
                   <div>
                     <div class="flex items-center gap-x-4">
                       <time :datetime="post.datetime" class="text-sm leading-6 text-gray-600">{{ post.date }}</time>
                       <a :href="post.category.href" class="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 text-xs font-medium text-gray-600 hover:bg-gray-100">{{ post.category.title }}</a>
                     </div>
                     <h4 class="mt-2 text-sm font-semibold leading-6 text-gray-900">
                       <a :href="post.href">
                         <span class="absolute inset-0" />
                         {{ post.title }}
                       </a>
                     </h4>
                     <p class="mt-2 text-sm leading-6 text-gray-600">{{ post.description }}</p>
                   </div>
                 </article>
               </div>
             </div>
           </PopoverPanel>
         </transition>
         
       </Popover>

       <a href="/Enterprise_Solutions" class="text-sm font-semibold leading-6 text-gray-900">Superiority</a>
       <a href="/contact" class="text-sm font-semibold leading-6 text-gray-900">Contact us</a>
     </PopoverGroup>
     <div class="hidden lg:flex lg:flex-1 lg:justify-end">
       <a href="/login" class="text-sm font-semibold leading-6 text-gray-900">Log in <span aria-hidden="true">&rarr;</span></a>
     </div>
   </nav>
   <Dialog class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
     <div class="fixed inset-0 z-10" />
     <DialogPanel class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
       <div class="flex items-center justify-between">
         <a href="#" class="-m-1.5 p-1.5">
           <span class="sr-only">Your Company</span>
           <img class="h-12 w-auto" src="@/assets/images/logo.svg" alt="" />
         </a>
         <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">
           <span class="sr-only">Close menu</span>
           <XMarkIcon class="h-6 w-6" aria-hidden="true" />
         </button>
       </div>
       <div class="mt-6 flow-root">
         <div class="-my-6 divide-y divide-gray-500/10">
           <div class="space-y-2 py-6">
             <Disclosure as="div" class="-mx-3" v-slot="{ open }">
               <DisclosureButton class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                 About us
                 <ChevronDownIcon :class="[open ? 'rotate-180' : '', 'h-5 w-5 flex-none']" aria-hidden="true" />
               </DisclosureButton>
               <DisclosurePanel class="mt-2 space-y-2">
                 <DisclosureButton v-for="item in engagement" :key="item.name" as="a" :href="item.href" class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50">{{ item.name }}</DisclosureButton>
                 <DisclosureButton v-for="item in resources" :key="item.name" as="a" :href="item.href" class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50">{{ item.name }}</DisclosureButton>
               </DisclosurePanel>
             </Disclosure>
             <a href="/Enterprise_Solutions" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Enterprise Solutions</a>
             <!-- <a href="#" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">For Advertisers</a> -->
             <a href="/contact" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Contact us</a>
           </div>
           <div class="py-6">
             <a href="/login" class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Log in</a>
           </div>
         </div>
       </div>
     </DialogPanel>
   </Dialog>
 </header>

 
 

 
</template>
<script>
export default {
 data() {
   return {
     hasShadow: false,
     isScrolled: false, 
   };
 },
 computed: {
   headerClass() {
     return {
       'shadow-lg': this.hasShadow,
       'bg-white': this.isScrolled,  
     };
   },
 },
 methods: {
   handleScroll() {
     const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
     this.hasShadow = scrollTop > 50;
     this.isScrolled = scrollTop > 0;
   },
 },
 mounted() {
   window.addEventListener('scroll', this.handleScroll);
 },
 beforeUnmount() {
   window.removeEventListener('scroll', this.handleScroll);
 },
};</script>

<script setup>
import { ref } from 'vue'
import { Popover, PopoverButton, PopoverPanel, PopoverGroup } from '@headlessui/vue'
import { Bars3Icon, ChevronDownIcon, XMarkIcon } from '@heroicons/vue/20/solid'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'

import {
 BookOpenIcon,
 BriefcaseIcon,
 GlobeAltIcon,
 InformationCircleIcon,
 NewspaperIcon,
 ShieldCheckIcon,
 UserGroupIcon,
 UsersIcon,
 VideoCameraIcon,

 ChartPieIcon,
 SquaresPlusIcon,
} from '@heroicons/vue/24/outline'

const mobileMenuOpen = ref(false)

const engagement = [
 { name: 'About AgencyMR', href: './about', icon: InformationCircleIcon },
 { name: 'Our Team', href: './team', icon: UserGroupIcon },
 // { name: 'Blog', href: '/blog', icon: NewspaperIcon },
 // { name: 'Careers', href: '#', icon: BriefcaseIcon },
 // { name: 'Privacy', href: '#', icon: ShieldCheckIcon },
]

const resources = [
 { name: 'Careers', href: '/careers', icon: UsersIcon },
 { name: 'Blog', href: '/blog', icon: NewspaperIcon },
 // { name: 'Guides', href: '#', icon: BookOpenIcon },
 // { name: 'Webinars', href: '#', icon: VideoCameraIcon },
]
const solutions = [
  { name: 'Sampling', description: 'Strategic selection of respondent groups to yield statistically robust insights', href: '/sampling', icon: ChartPieIcon },
  { name: 'Project Management', description: 'Coordinated oversight of survey operations to ensure accurate, timely project execution', href: '/Project_Management', icon: SquaresPlusIcon },
]
const recentPosts = [
 {
   id: 1,
   title: 'Online Sample: Preventing Duplicate Respondents',
   href: '/Preventing_Duplicate_Respondents',
   date: 'April 13, 2024',
   datetime: '2024-04-13',
   category: { title: 'DATA QUALITY', href: '/Preventing_Duplicate_Respondents' },
   imageUrl:
     'https://www.color-meanings.com/wp-content/uploads/woman-colored-mirror-1024x682.jpeg',
   description:
     'Ensuring that each respondent only takes your survey once is essential for ...',
 },
 {
   id: 2,
   title: 'Fine Tuning Online Data Collection: Understanding Fraud Detection Settings',
   href: '/Understanding_Fraud_Detection_Settings',
   date: 'Dec 06, 2023',
   datetime: '2023-12-06',
   category: { title: 'DATA QUALITY', href: '/Understanding_Fraud_Detection_Settings' },
   imageUrl:
     'https://blog.cloudflare.com/content/images/2023/03/image1-23.png',
   description: 'Fine-Tuning Online Data Collection: Navigating Fraud Detection Software...',
 },
]



</script>
