<template>
  
 
  <section>
  <div class="bg-white py-32 sm:py-64">
    <div class="mx-auto max-w-7xl px-6 text-left lg:px-8">
      <div class="mx-auto">
        <h1 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-6xl ">Privacy Policy</h1>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">AgencyMR, the operator of www.agencymr.com, with website and associated services values the privacy of our users, members, and others (collectively or individually "Users") who visit and use the Site. This statement governs information you provide to us or that we learn from your use of the Site and tells you how we may collect, use, and in some instances share, this information. By accessing or using the websites, participating in our surveys, or in any other services we provide (collectively, Services), you are agreeing to the practices set within this Privacy Policy. If you do not consent to our Privacy Policy, then you must not access or use our Services. Our Services, which may be provided through or in connection with third party mobile applications or third party websites, may redirect you to third party mobile applications or websites through which you accessed the Services.</p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900  mt-4 ">Information Collected</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif">User Provided Information: You may provide to AgencyMR what is generally called "personally identifiable" information when you register, such as your name and e-mail address. In addition, AgencyMR gives you the option to provide us with certain demographic information about yourself and to upload photographs or other personally identifiable information.</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">In order to become a member of the Site you are required to provide information through the registration process. At this time, AgencyMR will ask for your first name, last name, email address, zip code, gender, country, username, and password. Additional information such as your address, telephone number, IP address, and general demographic information such as postal code, marital status, education, relationship status, occupation history, ethnicity/race (where permitted by relevant law), health information, employment related information, and non-personally identifiable information on household members, household income, age, etc. may be asked. As a means to ensure consent around user data, you will be asked to provide confirmation that you have obtained consent on behalf of a family member, live-in member and spouse regarding their personally identifiable information.</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">AgencyMR is able to tailor its efforts to its users by receiving more and accurate information about users. You may edit your account information at any time by logging in to your account with your username and password and going to the My Preferences drop down under your name on the Dashboard. Any and all changes will be made immediately once you hit Save. Please update this information as you see fit so that AgencyMR maintains accurate information about you. AgencyMR seeks to ensure that any information collected remains relevant for the intent for which it is to be used and, to the extent necessary, AgencyMR takes reasonable steps to ensure that data is reliable, valid, complete, and current. "Automatically Collected" Information: When you visit the Site or open one of our HTML emails, we automatically collect and store information about your computer or mobile device along with your online activities. Referred to as “Response Metadata”, this information will be transferred and stored in accordance with the provisions contained in this Privacy Policy. Response Metadata (referred to as “Response Data”) and related information may be shared with survey generators and researchers along with clients and app developers. Response Data may also be provided to unaffiliated third parties for their own internal purposes, such as and not limited to marketing purposes. If you wish to opt-out of having your information shared with these Third Parties, please contact info@agencymr.com . You may also opt-out by deleting your account in its entirety.</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">Response Metadata, included and is not limited to the below:</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">"Cookies" Information: When you visit the Site, we may send one or more cookies – a small text file containing a string of alphanumeric characters – to your computer. Cookies are small files which are placed on your computer and allow us to perform analytics, operations of services and assessment of the usage of the Services – including but not limited to user preferences or trends (e.g., the Services may set a cookie on your browser allowing you to bypass engagement with a survey due to your previous completion of a survey). AgencyMR uses both session cookies and persistent cookies. A persistent cookie remains after you close your browser and may be used by your browser on subsequent visits to the Site. These persistent types of cookies can be removed. Each web browser implements different functionality, so please refer to the manuals or technical support resources that are available in conjunction with your web browser to learn the correct way to modify your cookies set-up. If you are using the Site in a jurisdiction where your prior consent is required for us to deploy any particular cookies, you will be asked to accept such cookies before they are deployed.</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">If you elect to disable or reject cookies, Services may not function properly. By doing so, you refuse any cookies from the Services and assume all responsibility of lacking or diminished functionality or benefits associated with the Services.</p>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif">
          <ul class="font-semibold">
            <li class="mt-2">1.Device Details: technical specifications about your computer or device (e.g., web browser, operating system, type of device, IP address, etc.)</li>
            <li class="mt-2">2.Geographic Location: IP address of your computer or device for approximate geographic location</li>
            <li class="mt-2">3.Time, Duration and Extent of Usage: during usage of service (e.g., time required to complete a survey) along with the Services feature(s) and/or function(s) utilized</li>
            <li class="mt-2">4.Web Beacons: potentially utilized in email communications as a means to understand the behavior of our users, “web beacons” (referred to as “pixel tags” or “clear gifs”) are small transparent graphic images that are implemented along with cookies to enhance and cater Services for our users from a collection of limited information.</li>
          </ul>
        </p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">"Optionally Collected" Information: By agreeing to participate and installing optional contextual tracking on your computer and/or mobile device, you are granting us permission to passively collect information about your online behavior. With collection of Mobile Device ID, we are able to collect mobile device identifiers including and not limited to Apple’s Identifier for Advertising ID (IDFA) and Google’s Android Advertising ID (AAID), to aid in our ability to identify you within a mobile application and match you to relevant survey opportunities.</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">When you use your computer, we may record the identity of the websites you visit, what time you visited those websites, how long you spent browsing or using them, the words and phrases you input into search engines, which search engines you use, the software and applications you use in order to browse online and use the internet. You have the ability to pause or disable the optional contextual tracking.</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">Additional information may be gathered and retained:</p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 mt-4 ">Survey Information</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif">When hosting surveys for our survey partners, AgencyMR may capture the information provided through these surveys. AgencyMR does not own this information. As such, any information you elect to provide to AgencyMR is used in accordance with the survey partner’s privacy practices.</p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 mt-4 ">User Feedback</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif">In the instance during which you provide AgencyMR with any comments, suggestions, or feedback related to our Services (referred to as “Feedback’), you understand that we may, although not obligated to do so, retain, utilize, disclose or otherwise commercially use your Feedback in connection with any or all of our Services without any compensation or consideration to you.</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">How We Use Your Information</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">AgencyMR uses the information that we collect to provide to you all of the features and services found on the Site. When you complete offers and surveys on the Site, AgencyMR will need to identify you so that we can determine you completed any offer or survey and that we can also credit your account accordingly. AgencyMR may also use your email address to correspond with you and notify you of offers or surveys that we deem to be a good fit. We may use your email address for marketing purposes, unless you have expressly notified us that you prefer for us not to use your email address for any specific marketing purposes ("opt-out"). While you may opt-out from such marketing use, you may not opt-out from our use of your email address for nonmarketing or administrative purposes such as automatic notifications of recent account activity or notifying you of major site updates.</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">Bear in mind that AgencyMR allows other users to view your name, other profile details and photographs should you provide this information and make it publicly viewable.</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">Per the terms of this Privacy Policy, we may use the information we collect from you for the following reasons:</p>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif">
          <ul class="font-semibold">
            <li class="mt-2">1.To assess and generate survey data for use with the Services and our third party customers;</li>
            <li class="mt-2">2.With the use of cookies, AgencyMR is able to collect information as a means to: (a) remember your information so that you will not have to re-enter it during your visit or the next time you visit the Site; (b) provide custom, personalized advertisements, content and information; (c) monitor the effectiveness of our marketing campaigns; and (d) monitor aggregate usage metrics such as total number of visitors and pages viewed.</li>
            <li class="mt-2">3.Develop new features and functionality</li>
            <li class="mt-2">4.Enhance the survey experience and overall survey delivers through the Services;</li>
            <li class="mt-2">5.To minimize instances of fraud and other illegal activities;</li>
            <li class="mt-2">6.To monitor and analyze any trends and usage using Google Analytics to provide us with aggregated information about how Users interact with the Site and setting cookies and/or use web beacons for this purpose. The information gathered by the Google Analytics software (including any personal information about you) will be sent to Google where it will be stored and used by Google in accordance with its own privacy policy rather than ours. Please review the Google Privacy Policy or contact Google for more information.</li>
            <li class="mt-2">7.To communicate with you about the various Services; and</li>
            <li class="mt-2">8.To furnish, uphold, and improve the Services and our business.</li>
          </ul>
        </p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">As such, where required by law, we require your prior opt-in consent at the time of collection for the processing of (i) personal information for marketing purposes and (ii) personal, sensitive information sensitive per applicable law.</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">You may also withdraw your consent or object at any time, on legitimate grounds, to the processing of your personal information by contacting AgencyMR at info@agencymr.com . Upon receiving your request, we will apply your preferences on a go forward basis. In some instances, withdrawing your consent to our use or disclosure of your personal information will dictate your ability to access certain services, surveys or promotional offers.</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">You may also opt-out and advise AgencyMR to terminate sending you any marketing communications by e-mail. This can be achieved by clicking on the unsubscribe link within the marketing emails you receive from us or by contacting AgencyMR at info@agencymr.com .</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">Optional contextual tracking will operate passively in the background and will not affect your connection speed. We will not intentionally use such contextual tracking to collect highly sensitive Personal Information such as passwords, usernames, social security or personal identification numbers, financial information (other than the fact that you may have visited the website or used the app of a financial institution or similar company) or health information (other than the fact that you have visited a health insurance or healthcare provider website or performed searches related to a particular medical condition or health concern).</p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 mt-4 ">Information Collected by Third Parties</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif">AgencyMR, by nature of its business, connects you with different third parties through their offers and surveys. In the event that you subscribe to or opt-in a third party offer or survey, AgencyMR may provide your information to the third party so that we can facilitate the relationship between you and the third party. AgencyMR may disclose information to our providers who provide services such as website hosting, information technology, data analysis, and infrastructure services, email delivery, auditing, customer service, and other services. In many cases you will be asked directly by third parties to give them your personally identifiable information. This may happen when you complete an offer or a survey or when you use their own websites or by other means. AgencyMR is not responsible for any information which you provide to Third Parties. AgencyMR suggests reading those privacy policies associated with those third parties.</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">AgencyMR may disclose both personally identifiable and automatically collected information to affiliated companies or other businesses or persons to process such information on our behalf, to provide website maintenance and security, to offer certain features on the Site, to assist us in improving the way the Site works and looks, and to create new features. We require that these parties agree to process such information in compliance with our Privacy Policy, we use reasonable efforts to limit their use of such information, and we require these parties to use any other appropriate confidentiality and security measures.</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">AgencyMR may share automatically collected and other aggregated non-personally-identifiable information with interested third parties for their use and distribution for their business purposes, including without limitation for the purposes of assisting them in understanding the usage patterns for certain content, services, advertisements, and promotions.</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">AgencyMR may share optionally collected information to develop statistics about Internet use and online activity and provide in aggregate for clients regarding trends in consumer behavior and consumer interest for future and/or present products and services.</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">We may share personally identifiable information with other third party organizations for their marketing or promotional use without your consent, except with respect to any specific program or feature for which you will have the ability to opt-out.</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">We may disclose your personally identifiable information if we believe in good faith that such disclosure is necessary to (a) comply with laws or to respond to subpoenas, court orders, warrants or other legal demands or to establish or defend AgencyMR from legal claims; or (b) to protect and defend our rights or property, you, or third parties from fraud, abuse or violations of the Terms of Service or illegal acts; or (c) to protect the safety or integrity of AgencyMR, its users, the public or other parties as it is deemed appropriate, in our sole discretion. You hereby consent to our sharing your personally identifying information under the circumstances described herein.</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">We may also share your personal information and/or social-demographic information, including and without limitation, a unique identification number ("UID"), postal code, gender, marital status, education, ethnicity/race (where permitted by applicable law), employment related information, and non-personally identifiable information on household members, with third party sample/market research companies (individually each a "Third Party MR Company" and collectively the "Third Party MR Companies") for the purpose of identifying survey opportunities that you may be eligible for through the Third Party MR Companies. As such, Third Party MR Companies may share your social-demographic information with their clients or vendors. This includes information shared with an acquirer, successor, or assignee during a reorganization effort, sale, merger, joint venture, transfer, assignment or disposition of part or all of our business, assets or stock including and not limited to bankruptcy. Likewise, if necessary by law or by necessity of good faith, Third Party MR companies may deem the following actions necessary:</p>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif">
          <ul class="font-semibold">
            <li class="mt-2">1.under applicable law, including laws outside your country of residence;</li>
            <li class="mt-2">2.to comply in accordance with legal process;</li>
            <li class="mt-2" >3.to reply to requests from public and government authorities, including public and government authorities outside your country of residence;</li>
            <li class="mt-2" >4.to enforce our terms and conditions;</li>
            <li class="mt-2" >5.to protect our operations or those of any of our affiliates;</li>
            <li class="mt-2" >6.to guard our rights, privacy, safety or property, and/or that of our affiliates, you or others; and</li>
            <li class="mt-2" >7.to allow us to pursue available remedies or limit the damages that we may sustain.</li>
          </ul>
        </p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">If you are eligible for a survey opportunity, the Third Party MR Company will provide your unique identification number along with a survey link to us and we will invite you to participate in the survey. We will not share your name, email address, or phone number with the Third Party MR Companies. In connection with the sharing of your information with a Third Party MR Company, your information may be transferred and stored outside of your country of residence, including, without limitation. If you have any questions about this data sharing please contact us using the details below or alternatively you can opt-out by deleting your account by navigating to your Preferences in AgencyMR. Your participation in such survey opportunities does not entitle you to any benefits offered by, or membership with, the Third Party MR Companies. If you have any inquiries surrounding data sharing or would like to opt-out of having your information shared with Third Party MR Companies, please contact AgencyMR at info@agencymr.com </p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">Except as otherwise noted in this Privacy Policy, AgencyMR does not share personal information about Users with third parties unless doing so is appropriate to carry out a User’s request or it reasonably believes that doing so is legally required or is in AgencyMR’ interest to protect its property or other legal rights or the rights or property of others.</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">AgencyMR’s accountability for personal data that it receives under the Privacy Shield and subsequently transfers to a third party is described in the Privacy Shield Principles. In particular, AgencyMR remains responsible and liable under the Privacy Shield Principles if third party agents that it engages to process the personal data on its behalf do so in a manner inconsistent with the Principles, unless AgencyMR proves that it is not responsible for the event giving rise to the damage.</p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 mt-4 ">User Profile and Password</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif">You have access to your personal information and you may update, correct, or delete your member information and email preferences at any time.</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">To protect your privacy and security, we take reasonable steps to verify your identity before granting Users profile access. YOU ARE RESPONSIBLE FOR MAINTAINING THE SECRECY OF YOUR UNIQUE PASSWORD AND ACCOUNT INFORMATION AT ALL TIMES.</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">PLEASE NOTE THAT WITH THE EXCEPTION OF CERTAIN CATEGORIES OF INFORMATION AS IDENTIFIED BY AgencyMR FROM TIME TO TIME, ALL INFORMATION THAT YOU POST FOR VIEWING ON THE SITE IS MADE PUBLICLY AVAILABLE TO ALL USERS OF THE SITE.</p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 mt-4 ">How We Protect Your Data</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif">While we aim to protect your personal information from losses, breaches, disclosure, modification, or destruction, through maintenance and security measures, any provided information is subject to unauthorized entry or use, hardware or software failure, and exposed to inherent risks associated with Internet usage; resulting in potential compromise to the overall security of your data at any point in time. As such, we cannot guaranty the secure state of your information.</p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 mt-4 ">Location of Data</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif">We may transfer the information we obtain about you to countries other than the country in which the information was initially obtained. In these instances, take note that some countries may not uphold the same data protection laws as the country in which you initially provided the data. We will protect your data as prescribed by this Privacy Policy when we transfer such data to other countries. By using the Services, you agree to the collection, use, transfer, storage and release of your information as described in this Privacy Policy. This includes the export of data outside of your country of residence. If you are accessing or using the Services outside of the United States, please be advised that your data, including any Response Data may be sent to, stored, and processed in the United States. Consequently, per the applicable United States laws and regulations, United States Federal and state governments, courts, or law enforcement or regulatory bodies may be able to obtain disclosure of your information such as your Response Data.</p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 mt-4 ">Child Protection</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif">Consistent with the federal Children’s Online Privacy Protection Act of 1998 (COPPA), we do not knowingly collect or solicit Response Data nor personally identifiable information from anyone under the age of sixteen (16). Any person who provides their personal information to AgencyMR through the Site represents that they are 16 years of age or older. In the case you are a parent or legal guardian who discovers that your child has provided us with any of the foregoing, you may contact AgencyMR info@agencymr.com  to delete their information.</p>
        
        
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 mt-4 ">Supplementary Privacy Policy for Specific Regions</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif">In addition to complying with global and regional privacy laws, AgencyMR pays special attention to the following specific regional privacy protection requirements:</p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 mt-4 ">California Consumer Privacy Act (CCPA)</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif">Under the California Consumer Privacy Act (CCPA), California residents have the right to know the categories of personal information we collect, the sources of that information, the purposes for which it is used, and the categories of information shared or sold to third parties. California residents also have the right to request the deletion of their personal information, opt-out of data sales, and not be discriminated against for exercising their privacy rights.</p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 mt-4 ">General Data Protection Regulation (GDPR)</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif">For users located in the European Union, AgencyMR complies with the General Data Protection Regulation (GDPR). We ensure that data processing is lawful and transparent, and we uphold users' data privacy rights, including the rights to access, correct, delete personal data, restrict processing, as well as data portability and the right to object.</p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 mt-4 ">Brazilian General Data Protection Law (LGPD)</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif">For users in Brazil, AgencyMR complies with the Brazilian General Data Protection Law (LGPD), ensuring the legality and transparency of data processing. Users have the right to know the personal information we collect, and they have the rights to access, correct, delete, and object to the processing of their data.</p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 mt-4 ">Australia Privacy Act</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif">Under the Australia Privacy Act, AgencyMR commits to managing the data of Australian residents securely and providing clear privacy policies and data processing notices. Users have the right to understand the purposes of using their personal information and to request access or correction of their information.</p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 mt-4 ">Personal Information Protection and Electronic Documents Act (PIPEDA)</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif">For Canadian residents, AgencyMR processes personal information under the Personal Information Protection and Electronic Documents Act (PIPEDA). Users have the right to access their personal data, understand the purpose of data collection and use, and have the right to correct or delete inaccurate data.</p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 mt-4 ">Personal Information Protection Law of China (PIPL)</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif">For users in China, AgencyMR complies with the requirements of the Personal Information Protection Law (PIPL). We ensure the processing of personal information is lawful, justifiable, and necessary, respecting users' rights to access, copy, correct, delete personal information, and withdraw consent.</p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 mt-4 ">Act on the Protection of Personal Information (APPI)</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif">When processing data of users in Japan, AgencyMR complies with the Act on the Protection of Personal Information (APPI) and takes appropriate security measures to prevent data breaches. Users have the right to request the purpose of processing their data, obtain a copy of their personal data, and correct or delete inaccurate data.</p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 mt-4 ">Protection of Personal Information Act (POPIA)</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif">For South African residents, AgencyMR processes personal information in accordance with the Protection of Personal Information Act (POPIA). Users have the right to know the purpose of processing their personal information and may access, correct, or delete their personal data.</p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 mt-4 ">Personal Data Protection Act (PDPA)</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif">For residents of Singapore, AgencyMR complies with the Personal Data Protection Act (PDPA), ensuring the legality of data processing and providing transparent privacy policies. Users have the right to access, correct, or withdraw consent for the use of their personal information.</p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 mt-4 ">Information Technology Act of India (IT Act)</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif">When processing data of users in India, AgencyMR complies with relevant provisions of the Information Technology Act (IT Act) and takes necessary technical and organizational measures to protect personal information. Users have the right to know the purpose of using their data and to request access, correction, or deletion of inaccurate personal information.</p>







        <h2 class="text-3xl font-bold tracking-tight text-gray-900 mt-4 ">How to Opt Out</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif">The Site provides you with the opportunity to affirmatively opt-out of receiving communications from us and our partners at the point where we request information about you. The Site gives you the option to end our use of your information, and to cease receiving or otherwise having access to future communications or services. Contact us at info@agencymr.com </p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 mt-4 ">Third Party Advertisers</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif">AgencyMR may allow other companies, called third party ad servers or ad networks, to serve advertisements within the Site. These third party ad servers or ad networks may use technology to send, directly to your browser, the advertisements and links that appear within the Site. They may automatically receive your IP Address when this happens. They may also use other technologies (such as cookies, JavaScript, or web beacons) to measure the effectiveness of their advertisements and to personalize their advertising content.</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">If your web browser includes a "Do Not Track" signal when you visit the Site, AgencyMR will not respond to the "Do Not Track" signal, because AgencyMR doesn’t track users across the web except as part of a specific program or feature for which you will have the ability to opt-in.</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">AgencyMR does not authorize the collection of personally identifiable information by, or provide personally identifiable information to, these third party ad servers or ad networks except as part of a specific program or feature for which you will have the ability to opt-in. Also, please note that if an advertiser asks AgencyMR to show an advertisement to a certain audience or audience segment and you respond to that advertisement, the advertiser or ad-server may conclude that you fit the description of the audience they were trying to reach.</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">You should consult the respective privacy policies of these third party ad servers or ad networks. AgencyMR’ Privacy Policy does not apply to, and we cannot control the activities of, such other ad servers or ad networks or their websites.</p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 mt-4 ">Data Security</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif">No data transmissions over the Internet can be guaranteed to be 100% secure. Consequently, we cannot ensure or warrant the security of any information you transmit to us and you do so at your own risk. Once we receive your transmission, we make reasonable efforts to ensure security on our systems. We use secure server software (SSL) and firewalls to protect your information from unauthorized access, disclosure, alteration, or destruction. However, please note that this is not a guarantee that such information may not be illegally or improperly accessed, disclosed, altered, or destroyed by breach of such firewalls and secure server software.</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">If AgencyMR learns of a security systems breach, then we may attempt to notify you electronically so that you can take appropriate protective steps. By using the Site or providing personal information to us, you agree that we can communicate with you electronically regarding security, privacy, and administrative issues relating to your use of the Site. AgencyMR may post a notice on the Site if a security breach occurs. If this happens, you will need a web browser enabling you to view the Site. AgencyMR may also send an email to you at the email address you have provided to us in these circumstances. Depending on where you live, you may have a legal right to receive notice of a security breach in writing. To receive a free written notice of a security breach (or to withdraw your consent from receiving electronic notice) you should notify us via email at info@agencymr.com .</p>

        <h2 class="text-3xl font-bold tracking-tight text-gray-900 mt-4 ">International Users</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif">If you are a User accessing the Site from the European Union, Asia, or any other region with laws or regulations governing personal data collection, use, and disclosure, that differ from United States laws, please note that you are transferring your personal data to the United States which does not have the same data protection laws as the EU and other regions, and by providing your personal data you consent to:</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">the use of your personal data for the uses identified above in accordance with the Privacy Policy; and the transfer of your personal data to the United States as indicated above. YOU ACKNOWLEDGE THAT THE LAWS OF THE UNITED STATES TREAT YOUR INFORMATION IN A MANNER THAT MAY BE SUBSTANTIALLY DIFFERENT FROM, AND LESS PROTECTIVE THAN, THE TREATMENT REQUIRED UNDER THE LAWS OF OTHER COUNTRIES AND JURISDICTIONS. IF YOU DO NOT WANT YOUR INFORMATION TRANSFERRED TO THE UNITED STATES, YOU SHOULD NOT SHARE YOUR INFORMATION WITH US, OR MAKE USE OF THE SITE.</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif">TO THE EXTENT ALLOWED BY THE LAW OF THE COUNTRY IN WHICH YOU ARE LOCATED, YOU EXPRESSLY WAIVE ANY RIGHT YOU MAY HAVE TO REQUIRE US TO TREAT YOUR IDENTIFYING INFORMATION IN ACCORDANCE WITH THE LAWS OF ANY COUNTRY OR JURISDICTION OTHER THAN THE UNITED STATES. HOWEVER, THE FOREGOING WAIVER MAY NOT BE LEGALLY BINDING IN SOME COUNTRIES, SUCH AS THE MEMBER STATES OF THE EUROPEAN UNION. TO THE EXTENT IT IS NOT LEGALLY BINDING IN THE COUNTRY IN WHICH YOU ARE LOCATED, THIS FOREGOING WAIVER DOES NOT APPY TO YOU.</p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 mt-4 ">In the Event of Merger or Sale</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif">If AgencyMR should ever file for bankruptcy or have its assets sold to or merge with another entity, information AgencyMR receives from you through the Site is a AgencyMR asset and may be transferred to another party; however, in the event of such a transfer, we will require that the transferee agree to honor this Privacy Policy, as it applies to Users prior to the effective date of any such transfer, unless we are legally prohibited from doing so.</p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 mt-4 ">Changes to This Privacy Policy</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif">AgencyMR reserves the right, at our discretion, to change, modify, add, or remove portions of this Privacy Policy at any time for any reason. With any changes to the Privacy Policy, AgencyMR will display a "last updated" date found at the top of this Privacy Policy. Each time AgencyMR exercises such right, you shall be required to affirmatively accept the updated Privacy Policy, and if you fail to do so, you will no longer be able to access or use the Site.</p>
        <h2 class="text-xl font-bold tracking-tight text-gray-900  mt-12 ">Last updated: 22-Oct-2023</h2>
      </div>
  
    </div>
  </div>
  </section>
  <router-view />

</template>

<script>

</script>

<style>

</style>