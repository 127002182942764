<!-- App.vue -->
<template>
  
  <div>
    


  <div class="bg-white">
    <main>
      <div class="relative isolate">
        <svg class="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]" aria-hidden="true">
          <defs>
            <pattern id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
              <path d="M.5 200V.5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y="-1" class="overflow-visible fill-gray-50">
            <path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z" stroke-width="0" />
          </svg>
          <rect width="100%" height="100%" stroke-width="0" fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" />
        </svg>
        <div class="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48" aria-hidden="true">
          <div class="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30" style="clip-path: polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)" />
        </div>
        <div class="overflow-hidden">
          <div class="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
            <div class="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
              <div class="relative w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl"  style="color: #0D2254;">Reinventing Research Accessibility.</h1>
                <p class="mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">At AgencyMR, we believe in simplifying the research process. Our platform provides direct access to a vast range of global audiences, making it easy to gather reliable data and insights efficiently and effectively.</p>
                <div class="mt-10 flex items-center gap-x-6">
                  <a href="/login" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Get started</a>
                  <a href="/contact" class="text-sm font-semibold leading-6 text-gray-900">Request A Demo<span aria-hidden="true">→</span></a>
                </div>
              </div>
              <div class="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
                <div class="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                  <div class="relative">
                    <img src="@/assets/images/z2.jpg" alt="" class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg" />
                    <div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                </div>
                <div class="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                  <div class="relative">
                    <img src="@/assets/images/z3.jpg" alt="" class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg" />
                    <div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                  <div class="relative">
                    <img src="@/assets/images/z4.jpg" alt="" class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg" />
                    <div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                </div>
                <div class="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                  <div class="relative">
                    <img src="@/assets/images/z5.jpg" alt="" class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg" />
                    <div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                  <div class="relative">
                    <img src="@/assets/images/z6.jpg" alt="" class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg" />
                    <div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="@/assets/images/m1.png" alt="Transistor" width="158" height="48" />
          <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="@/assets/images/m2.png" alt="Reform" width="158" height="48" />
          <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="@/assets/images/m3.png" alt="Tuple" width="158" height="48" />
          <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="@/assets/images/m4.png" alt="Tuple" width="158" height="48" />
          <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="@/assets/images/m5.png" alt="Tuple" width="158" height="48" />
          <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="@/assets/images/m6.png" alt="Tuple" width="158" height="48" />
          <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="@/assets/images/m7.png" alt="Tuple" width="158" height="48" />
          <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="@/assets/images/m8.png" alt="Tuple" width="158" height="48" />
          <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="@/assets/images/m9.png" alt="Tuple" width="158" height="48" />
          <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="@/assets/images/m10.png" alt="Tuple" width="158" height="48" />  
        </div>
      </div>
 
  <!-- 特点1-->
  <div class="overflow-hidden bg-white py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
        <div class="lg:ml-auto lg:pl-4 lg:pt-4">
          <div class="lg:max-w-lg">
            <h2 class="text-base font-semibold leading-7 text-indigo-600">Higher accuracy</h2>
            <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"  style="color: #0D2254;">Empowering Your Research with AI and Data Integration.</p>
            <p class="mt-6 text-lg leading-8 text-gray-600">AgencyMR harnesses the power of AI and comprehensive data profiling to enhance the accuracy and reach of your surveys. By partnering with top data providers worldwide, we ensure you have the best tools and resources at your fingertips.</p>
            <dl class="mt-10 max-w-xl grid grid-cols-1 md:grid-cols-2 gap-8 text-base leading-7 text-gray-600 lg:max-w-none">
            <div v-for="feature in features" :key="feature.name" class="relative pl-9">
              <dt class="inline font-semibold text-gray-900">
                <component :is="feature.icon" class="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                {{ feature.name }}
              </dt>
              {{ ' ' }}
              <dd class="inline">{{ feature.description }}</dd>
            </div>
          </dl>
          </div>
        </div>
        <div class="flex items-start justify-end lg:order-first">
          <img src="@/assets/images/z15.jpg" alt="Product screenshot" class="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]" width="2432" height="1442" />
        </div>
      </div>
    </div>
  </div>
  <!-- 特点1 -->

  <!-- 分析 -->
  <div class="bg-white py-24 sm:pt-24 pb-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"  style="color: #0D2254;">Our mission is to enhance market research and add value to businesses worldwide.</h2>
        <p class="mt-6 text-base leading-7 text-gray-600">At AgencyMR, we believe in creating tools that not only make research easier but also empower businesses to make informed decisions that drive success. Join us in shaping the future of market research.</p>
      </div>
      <div class="mx-auto mt-16 flex max-w-2xl flex-col gap-8 lg:mx-0 lg:mt-20 lg:max-w-none lg:flex-row lg:items-end">
        <div class="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-50 p-8 sm:w-3/4 sm:max-w-md sm:flex-row-reverse sm:items-end lg:w-72 lg:max-w-none lg:flex-none lg:flex-col lg:items-start">
          <p class="flex-none text-3xl font-bold tracking-tight text-gray-900">1.2 million</p>
          <div class="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
            <p class="text-lg font-semibold tracking-tight text-gray-900">Active participants</p>
            <p class="mt-2 text-base leading-7 text-gray-600">We connect you with a vast network of engaged survey respondents, ensuring your research reaches the right audience.</p>
          </div>
        </div>
        <div class="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-900 p-8 sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-sm lg:flex-auto lg:flex-col lg:items-start lg:gap-y-44">
          <p class="flex-none text-3xl font-bold tracking-tight text-white">$12 million</p>
          <div class="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
            <p class="text-lg font-semibold tracking-tight text-white">Revenue generated for our clients</p>
            <p class="mt-2 text-base leading-7 text-gray-400">Our clients have seen substantial returns on their investment in market research, driving over $12 million in revenue through better insights.</p>
          </div>
        </div>
        <div class="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-indigo-600 p-8 sm:w-11/12 sm:max-w-xl sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-none lg:flex-auto lg:flex-col lg:items-start lg:gap-y-28">
          <p class="flex-none text-3xl font-bold tracking-tight text-white">520,000</p>
          <div class="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
            <p class="text-lg font-semibold tracking-tight text-white">Project completed this year</p>
            <p class="mt-2 text-base leading-7 text-indigo-200">We’ve helped businesses conduct over half a million surveys this year alone, providing them with the data they need to succeed.</p>
          </div>
        </div>
      </div>
    </div>
  </div> 
  <!-- 分析 -->

  <!-- 数据 -->
  <div class="relative bg-white">
    <img class="h-56 w-full bg-gray-50 object-cover lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-1/2" src="@/assets/images/z6.jpg" alt="" />
    <div class="mx-auto grid max-w-7xl lg:grid-cols-2">
      <div class="px-6 pb-24 pt-16 sm:pb-32 sm:pt-20 lg:col-start-2 lg:px-8 lg:pt-32">
        <div class="mx-auto max-w-2xl lg:mr-0 lg:max-w-lg">
          <h2 class="text-base font-semibold leading-8 text-indigo-600">Why choose AgencyMR?</h2>
          <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"  style="color: #0D2254;">Trusted by top researchers&nbsp;globally</p>
          <p class="mt-6 text-lg leading-8 text-gray-600">At AgencyMR, we’re proud to support thousands of researchers and companies worldwide. Our platform is designed to be a trusted partner in all your market research needs.</p>
          <dl class="mt-16 grid max-w-xl grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 xl:mt-16">
            <div v-for="stat in stats" :key="stat.id" class="flex flex-col gap-y-3 border-l border-gray-900/10 pl-6">
              <dt class="text-sm leading-6 text-gray-600">{{ stat.name }}</dt>
              <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900">{{ stat.value }}</dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
  <!-- 数据 -->

  <!-- FAQ section -->
  <div class="mt-32 overflow-hidden sm:mt-40">
      <div class="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
        <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
          <div class="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
            <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"  style="color: #0D2254;">Optimized Research, Unmatched Quality</h2>
            <p class="mt-6 text-base leading-7 text-gray-600">Empower your research with AgencyMR's comprehensive platform. Enjoy real-time data insights, transparent cost management, and rigorous quality control, all designed to support your data-driven decisions and maximize research outcomes.</p>
            
            <div class="flex items-center space-x-2">
            <a href="/Enterprise_Solutions" class="underline decoration-sky-600 hover:decoration-blue-400 font-bold">
              <p class="mt-6 text-xl leading-7 text-gray-600">Learn more</p>
            </a>
            <ArrowRightIcon class="mt-6 h-5 w-5 flex-none text-[#6d6d6d]" aria-hidden="true" />
            </div>

          </div>
          <div class="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
            <div class="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
              <img src="@/assets/images/z7.jpg" alt="" class="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover">
            </div>
            <div class="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
              <div class="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
                <img src="@/assets/images/z11.jpg" alt="" class="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover">
              </div>
              <div class="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                <img src="@/assets/images/z14.jpg" alt="" class="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover">
              </div>
              <div class="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                <img src="@/assets/images/z9.jpg" alt="" class="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      
  <!-- FAQ section-->
  <!-- 对比栏 -->
  <div class="isolate overflow-hidden">

<div class="relative bg-gray-50 lg:pt-14">
<div class="mx-auto max-w-7xl px-6 py-24 sm:pt-18 pb-32 lg:px-8">
  <!-- Feature comparison (up to lg) -->
  <div class="mx-auto max-w-2xl text-center">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-2xl" style="color: #0D2254;">Discover the Unique Benefits of AgencyMR</h2>
        <p class="mt-4 mb-8 text-md leading-8 text-gray-600">Explore how AgencyMR sets itself apart from other platforms with innovative features and exceptional services that elevate your research experience.</p>
  </div>

  <section aria-labelledby="mobile-comparison-heading" class="lg:hidden">
    <h2 id="mobile-comparison-heading" class="sr-only">Feature comparison</h2>

    <div class="mx-auto max-w-2xl space-y-16">
      <div v-for="tier in tiers" :key="tier.id" class="border-t border-gray-900/10">
        <div :class="[tier.featured ? 'border-indigo-600' : 'border-transparent', '-mt-px w-72 border-t-2 pt-10 md:w-80']">
          <h3 :class="[tier.featured ? 'text-indigo-600' : 'text-gray-900', 'text-sm font-semibold leading-6']">{{ tier.name }}</h3>
        </div>

        <div class="mt-10 space-y-10">
          <div v-for="section in sections" :key="section.name">
            <h4 class="text-sm font-semibold leading-6 text-gray-900">{{ section.name }}</h4>
            <div class="relative mt-6">
              <div aria-hidden="true" class="absolute inset-y-0 right-0 hidden w-1/2 rounded-lg bg-white shadow-sm sm:block" />

              <div :class="[tier.featured ? 'ring-2 ring-indigo-600' : 'ring-1 ring-gray-900/10', 'relative rounded-lg bg-white shadow-sm sm:rounded-none sm:bg-transparent sm:shadow-none sm:ring-0']">
                <dl class="divide-y divide-gray-200 text-sm leading-6">
                  <div v-for="feature in section.features" :key="feature.name" class="flex items-center justify-between px-4 py-3 sm:grid sm:grid-cols-2 sm:px-0">
                    <dt class="pr-4 text-gray-600">{{ feature.name }}</dt>
                    <dd class="flex items-center justify-end sm:justify-center sm:px-4">
                      <span v-if="typeof feature.tiers[tier.name] === 'string'" :class="tier.featured ? 'font-semibold text-indigo-600' : 'text-gray-900'">{{ feature.tiers[tier.name] }}</span>
                      <template v-else>
                        <CheckIcon v-if="feature.tiers[tier.name] === true" class="mx-auto h-5 w-5 text-indigo-600" aria-hidden="true" />
                        <XMarkIcon v-else class="mx-auto h-5 w-5 text-gray-400" aria-hidden="true" />
                        <span class="sr-only">{{ feature.tiers[tier.name] === true ? 'Yes' : 'No' }}</span>
                      </template>
                    </dd>
                  </div>
                </dl>
              </div>

     
              <div aria-hidden="true" :class="[tier.featured ? 'ring-2 ring-indigo-600' : 'ring-1 ring-gray-900/10', 'pointer-events-none absolute inset-y-0 right-0 hidden w-1/2 rounded-lg sm:block']" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Feature comparison (lg+) -->
  <section aria-labelledby="comparison-heading" class="hidden lg:block">
    <h2 id="comparison-heading" class="sr-only">Feature comparison</h2>

    <div class="grid grid-cols-4 gap-x-8 border-t border-gray-900/10 before:block">
      <div v-for="tier in tiers" :key="tier.id" aria-hidden="true" class="-mt-px">
        <div :class="[tier.featured ? 'border-indigo-600' : 'border-transparent', 'border-t-2 pt-10']">
          <p :class="[tier.featured ? 'text-indigo-600' : 'text-gray-900', 'text-xl font-semibold leading-6']">{{ tier.name }}</p>
          <!-- <p class="mt-1 text-sm leading-6 text-gray-600">{{ tier.description }}</p> -->
        </div>
      </div>
    </div>

    <div class="-mt-6 space-y-16">
      <div v-for="section in sections" :key="section.name">
        <h3 class="text-sm font-semibold leading-6 text-gray-900">{{ section.name }}</h3>
        <div class="relative -mx-8 mt-10">
          <!-- Fake card backgrounds -->
          <div class="absolute inset-x-8 inset-y-0 grid grid-cols-4 gap-x-8 before:block" aria-hidden="true">
            <div class="h-full w-full rounded-lg bg-white shadow-sm" />
            <div class="h-full w-full rounded-lg bg-white shadow-sm" />
            <div class="h-full w-full rounded-lg bg-white shadow-sm" />
          </div>

          <table class="relative w-full border-separate border-spacing-x-8">
            <thead>
              <tr class="text-left">
                <th scope="col">
                  <span class="sr-only">Feature</span>
                </th>
                <th v-for="tier in tiers" :key="tier.id" scope="col">
                  <span class="sr-only">{{ tier.name }} tier</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(feature, featureIdx) in section.features" :key="feature.name">
                <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-normal leading-6 text-gray-900">
                  {{ feature.name }}
                  <div v-if="featureIdx !== section.features.length - 1" class="absolute inset-x-8 mt-3 h-px bg-gray-200" />
                </th>
                <td v-for="tier in tiers" :key="tier.id" class="relative w-1/4 px-4 py-0 text-center">
                  <span class="relative h-full w-full py-3">
                    <span v-if="typeof feature.tiers[tier.name] === 'string'" :class="[tier.featured ? 'font-semibold text-indigo-600' : 'text-gray-900', 'text-sm leading-6']">{{ feature.tiers[tier.name] }}</span>
                    <template v-else>
                      <CheckIcon v-if="feature.tiers[tier.name] === true" class="mx-auto h-5 w-5 text-indigo-600" aria-hidden="true" />
                      <XMarkIcon v-else class="mx-auto h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span class="sr-only">{{ feature.tiers[tier.name] === true ? 'Yes' : 'No' }}</span>
                    </template>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- Fake card borders -->
          <div class="pointer-events-none absolute inset-x-8 inset-y-0 grid grid-cols-4 gap-x-8 before:block" aria-hidden="true">
            <div v-for="tier in tiers" :key="tier.id" :class="[tier.featured ? 'ring-2 ring-indigo-600' : 'ring-1 ring-gray-900/10', 'rounded-lg']" />
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
</div>
  </div>
  <!-- 对比栏 -->



  <!-- blog -->
  <section>
    <div class="bg-white py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl text-center my-16">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"  style="color: #0D2254;">AgencyMR Blog  </h2>
        <p class="mt-6  mb-24 text-md leading-8 text-gray-600">Library of the latest insights, press, resources, and trends in Market Research</p>
      </div>
      <div class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <article v-for="post in posts" :key="post.id" class="flex flex-col items-start justify-between">
          <div class="relative w-full">
            <img :src="post.imageUrl" alt="" class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]" />
            <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
          </div>
          <div class="max-w-xl">
            <div class="mt-8 flex items-center gap-x-4 text-xs">
              <time :datetime="post.datetime" class="text-gray-500">{{ post.date }}</time>
              <a :href="post.category.href" class="relative z-1 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100">{{ post.category.title }}</a>
            </div>
            <div class="group relative">
              <h3 class="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                <a :href="post.href">
                  <span class="absolute inset-0" />
                  {{ post.title }}
                </a>
              </h3>
              <p class="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{{ post.description }}</p>
            </div>
         
          </div>
        </article>
      </div>
    </div>
  </div>
  </section>
  <!-- blog -->

  <router-view />
 
  <div class="bg-indigo-100">
    <div class="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8">
      <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">Learn how we can help you?<br />We’ll connect you with a AgencyMR expert!</h2>
      <div class="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
        <a href="/contact" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Get started</a>
        <a href="/Enterprise_Solutions" class="text-sm font-semibold leading-6 text-gray-900">Learn more <span aria-hidden="true">→</span></a>
      </div>
    </div>
  </div>
  </div>
</template>


<script setup>

import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { CloudArrowUpIcon, LockClosedIcon, ServerIcon,ArrowRightIcon, PresentationChartBarIcon,BeakerIcon, ChatBubbleBottomCenterIcon, ChatBubbleLeftRightIcon, CubeIcon, ServerStackIcon, UserCircleIcon, ClipboardDocumentCheckIcon, FaceSmileIcon } from '@heroicons/vue/20/solid'
import { CheckIcon, XMarkIcon } from '@heroicons/vue/20/solid'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/vue/24/outline'



// 对比栏
const tiers = [
  {
    name: 'Marketplace',
    id: 'tier-Marketplace',
    href: '#',
    featured: false,
    description: 'All your essential business finances, taken care of.',
    price: { monthly: '$15', annually: '$144' },
    mainFeatures: ['Basic invoicing', 'Easy to use accounting', 'Mutli-accounts'],
  },
  {
    name: 'AgencyMR',
    id: 'tier-AgencyMR',
    href: '#',
    featured: true,
    description: 'The best financial services for your thriving business.',
    price: { monthly: '$60', annually: '$576' },
    mainFeatures: [
      'Advanced invoicing',
      'Easy to use accounting',
      'Mutli-accounts',
      'Tax planning toolkit',
      'VAT & VATMOSS filing',
      'Free bank transfers',
    ],
  },
  {
    name: 'Custom',
    id: 'tier-Custom',
    href: '#',
    featured: false,
    description: 'Convenient features to take your business to the next level.',
    price: { monthly: '$30', annually: '$288' },
    mainFeatures: ['Basic invoicing', 'Easy to use accounting', 'Mutli-accounts', 'Tax planning toolkit'],
  },
]
const sections = [
  {
    name: 'Features',
    features: [
      { name: 'Instant Data Analysis', tiers: { Marketplace: true, AgencyMR: true, Custom: false } },
      { name: 'Multiple Vendor Integration', tiers: { Marketplace: false, AgencyMR: true, Custom: true } },
      { name: '24/7 Project Launch', tiers: { Marketplace: false, AgencyMR: true, Custom: true } },
      { name: 'No Subscription Fees', tiers: { Marketplace: false, AgencyMR: true, Custom: false } },
      { name: 'Advanced Data Validation', tiers: { Marketplace: false, AgencyMR: true, Custom: true } },
      { name: 'Customizable Dashboards', tiers: { Marketplace: false, AgencyMR: true, Custom: true } },
    ],
  },

]
// 对比栏


// 特点1控制器
const features = [
  {
    name: 'Product Feedback',
    icon: PresentationChartBarIcon,
  },
  {
    name: 'Customer Experience',
    icon: FaceSmileIcon,
  },
  {
    name: 'Market Research',
    icon: ClipboardDocumentCheckIcon,
  },
  {
    name: 'Brand Insights',
    icon: CubeIcon,
  },
  {
    name: 'Employee Engagement',
    icon: UserCircleIcon,
  },
  {
    name: 'Healthcare Studies',
    icon: BeakerIcon,
  },
  {
    name: 'B2B Analysis',
    icon: ChatBubbleLeftRightIcon,
  },
]
// faq控制器

// 数据控制器
const stats = [
  { id: 1, name: 'Researchers on the platform', value: '10,000+' },
  { id: 2, name: 'No management fees', value: '0' },
  { id: 3, name: 'Uptime guarantee', value: '99.9%' },
  { id: 4, name: 'Revenue shared with participants', value: ' Over $1 million' },
]
// blog控制器
const posts = [
  {
    id: 1,
    title: 'Online Sample: Preventing Duplicate Respondents',
    href: '/Preventing_Duplicate_Respondents',
    description:
      'Ensuring that each respondent only takes your survey once is essential for maintaining data integrity. Duplicate responses can skew results for several reasons...',
    imageUrl:
      'https://www.color-meanings.com/wp-content/uploads/woman-colored-mirror-1024x682.jpeg',
    date: 'April 13, 2024',
    datetime: '2024-04-13',
    category: { title: 'DATA QUALITY', href: '/Preventing_Duplicate_Respondents' },
  },
  {
    id: 2,
    title: 'Fine Tuning Online Data Collection: Understanding Fraud Detection Settings',
    href: '/Understanding_Fraud_Detection_Settings',
    description:
      'Fine-Tuning Online Data Collection: Navigating Fraud Detection Software Simply informing clients that fraud detection software is in place is not enough. A solid understanding of the software is crucial to maintain the integrity of your data collection efforts.',
    imageUrl:
      'https://blog.cloudflare.com/content/images/2023/03/image1-23.png',
    date: 'Dec 06, 2023',
    datetime: '2023-12-06',
    category: { title: 'DATA QUALITY', href: '/Understanding_Fraud_Detection_Settings' },
  },
  {
    id: 3,
    title: 'Online Survey Length – How Long is Too Long?',
    href: '/Online_Survey_Length',
    description:
      'If I had a dollar for every time a client asked me how long their survey should be, I’d be able to fund a week-long trip to Disney for my family of six.',
    imageUrl:
      'https://images.ctfassets.net/lzny33ho1g45/4Ql0NqhccLsD4OYL7BUaDZ/16340c449bc237a04c29e612dbb902b8/best_survey_apps.jpg',
    date: 'Oct 11, 2023',
    datetime: '2023-10-11',
    category: { title: 'DATA QUALITY', href: '/Online_Survey_Length' },
  },
  
]


</script>

<style>
/* 样式 */
</style>
