<template>
  <div class="bg-white">
    
    <div class="mx-auto px-4 py-16 sm:px-6 sm:pt-36 pb-24 lg:max-w-7xl lg:px-8">
      <div class="mx-auto max-w-2xl text-center my-16">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"  style="color: #0D2254;">Data-Driven Decisions Made <span style=" background: -webkit-linear-gradient(40deg, rgb(126 194 255) 0%, rgb(208 142 247) 50%);-webkit-background-clip: text;-webkit-text-fill-color: transparent;display: inline; ">Easy</span> </h2>
        <p class="mt-6  mb-24 text-md leading-8 text-gray-600">With AgencyMR, you gain a deeper understanding of your target audience through accurate, real-time data. Our platform is designed to support informed decision-making by providing a clear view of your research landscape.</p>
      </div>
      <!-- 第一段内容 -->
      <div class="lg:grid lg:grid-cols-7 lg:grid-rows-1 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
        <!-- 产品图片 -->
        <div class="lg:col-span-4 lg:row-end-1">
          <div class="aspect-h-3 aspect-w-4 overflow-hidden rounded-lg bg-gray-100">
            <img :src="product1.imageSrc" :alt="product1.imageAlt" class="object-cover object-center" />
          </div>
        </div>

        <!-- 产品详情 -->
        <div class="mx-auto mt-14 max-w-2xl sm:mt-16 lg:col-span-3 lg:row-span-2 lg:row-end-2 lg:mt-0 lg:max-w-none">
          <div class="flex flex-col-reverse">
            <div class="mt-4">
              <h1 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl" style="color: #0D2254;">{{ product1.name }}</h1>

              <h2 id="information-heading" class="sr-only">Product information</h2>
        

            </div>

            <div>
              <h3 class="sr-only">Reviews</h3>
              <div class="flex items-center">
                <StarIcon v-for="rating in [0, 1, 2, 3, 4]" :key="rating" :class="[reviews1.average > rating ? 'text-yellow-400' : 'text-gray-300', 'h-5 w-5 flex-shrink-0']" aria-hidden="true" />
              </div>
              <p class="sr-only">{{ reviews1.average }} out of 5 stars</p>
            </div>
          </div>

          <p class="mt-6 text-gray-500">{{ product1.description }}</p>

          <div class="mt-10 border-t border-gray-200 pt-10">
            <h3 class="text-xl font-medium text-gray-900">Advantages</h3>
            <div class="prose prose-sm mt-4 text-gray-500">
              <ul role="list">
                <li v-for="highlight in product1.highlights" :key="highlight">{{ highlight }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- 第二段内容 -->
      <div class="mx-auto px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <div class="lg:grid lg:grid-cols-7 lg:grid-rows-1 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
          <!-- 产品详情 -->
          <div class="mx-auto mt-14 max-w-2xl sm:mt-16 lg:col-span-3 lg:row-span-2 lg:row-end-2 lg:mt-0 lg:max-w-none">
            <div class="flex flex-col-reverse">
              <div class="mt-4">
                <h1 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl" style="color: #0D2254;">{{ product2.name }}</h1>
                <h2 id="information-heading" class="sr-only">Product information</h2>
              </div>

              <div>
                <h3 class="sr-only">Reviews</h3>
                <div class="flex items-center">
                  <StarIcon v-for="rating in [0, 1, 2, 3, 4]" :key="rating" :class="[reviews2.average > rating ? 'text-yellow-400' : 'text-gray-300', 'h-5 w-5 flex-shrink-0']" aria-hidden="true" />
                </div>
                <p class="sr-only">{{ reviews2.average }} out of 5 stars</p>
              </div>
            </div>

            <p class="mt-6 text-gray-500">{{ product2.description }}</p>

            <div class="mt-10 border-t border-gray-200 pt-10">
              <h3 class="text-xl font-medium text-gray-900">Advantages</h3>
              <div class="prose prose-sm mt-4 text-gray-500">
                <ul role="list">
                  <li v-for="highlight in product2.highlights" :key="highlight">{{ highlight }}</li>
                </ul>
              </div>
            </div>
          </div>

          <!-- 产品图片 -->
          <div class="lg:col-span-4 lg:row-end-1">
            <div class="aspect-h-3 aspect-w-4 overflow-hidden rounded-lg bg-gray-100">
              <img :src="product2.imageSrc" :alt="product2.imageAlt" class="object-cover object-center" />
            </div>
          </div>
        </div>
      </div>

      <!-- 第三段内容 -->
      <div class="lg:grid lg:grid-cols-7 lg:grid-rows-1 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
        <!-- 产品图片 -->
        <div class="lg:col-span-4 lg:row-end-1">
          <div class="aspect-h-3 aspect-w-4 overflow-hidden rounded-lg bg-gray-100">
            <img :src="product3.imageSrc" :alt="product3.imageAlt" class="object-cover object-center" />
          </div>
        </div>

        <!-- 产品详情 -->
        <div class="mx-auto mt-14 max-w-2xl sm:mt-16 lg:col-span-3 lg:row-span-2 lg:row-end-2 lg:mt-0 lg:max-w-none">
          <div class="flex flex-col-reverse">
            <div class="mt-4">
              <h1 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl" style="color: #0D2254;">{{ product3.name }}</h1>

              <h2 id="information-heading" class="sr-only">Product information</h2>
        

            </div>

            <div>
              <h3 class="sr-only">Reviews</h3>
              <div class="flex items-center">
                <StarIcon v-for="rating in [0, 1, 2, 3, 4]" :key="rating" :class="[reviews3.average > rating ? 'text-yellow-400' : 'text-gray-300', 'h-5 w-5 flex-shrink-0']" aria-hidden="true" />
              </div>
              <p class="sr-only">{{ reviews3.average }} out of 5 stars</p>
            </div>
          </div>

          <p class="mt-6 text-gray-500">{{ product3.description }}</p>

          <div class="mt-10 border-t border-gray-200 pt-10">
            <h3 class="text-xl font-medium text-gray-900">Advantages</h3>
            <div class="prose prose-sm mt-4 text-gray-500">
              <ul role="list">
                <li v-for="highlight in product3.highlights" :key="highlight">{{ highlight }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>


      <!-- 第四段内容 -->
      <div class="mx-auto px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <div class="lg:grid lg:grid-cols-7 lg:grid-rows-1 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
          <!-- 产品详情 -->
          <div class="mx-auto mt-14 max-w-2xl sm:mt-16 lg:col-span-3 lg:row-span-2 lg:row-end-2 lg:mt-0 lg:max-w-none">
            <div class="flex flex-col-reverse">
              <div class="mt-4">
                <h1 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl" style="color: #0D2254;">{{ product4.name }}</h1>
                <h2 id="information-heading" class="sr-only">Product information</h2>
              </div>

              <div>
                <h3 class="sr-only">Reviews</h3>
                <div class="flex items-center">
                  <StarIcon v-for="rating in [0, 1, 2, 3, 4]" :key="rating" :class="[reviews4.average > rating ? 'text-yellow-400' : 'text-gray-300', 'h-5 w-5 flex-shrink-0']" aria-hidden="true" />
                </div>
                <p class="sr-only">{{ reviews4.average }} out of 5 stars</p>
              </div>
            </div>

            <p class="mt-6 text-gray-500">{{ product4.description }}</p>

            <div class="mt-10 border-t border-gray-200 pt-10">
              <h3 class="text-xl font-medium text-gray-900">Advantages</h3>
              <div class="prose prose-sm mt-4 text-gray-500">
                <ul role="list">
                  <li v-for="highlight in product4.highlights" :key="highlight">{{ highlight }}</li>
                </ul>
              </div>
            </div>
          </div>

          <!-- 产品图片 -->
          <div class="lg:col-span-4 lg:row-end-1">
            <div class="aspect-h-3 aspect-w-4 overflow-hidden rounded-lg bg-gray-100">
              <img :src="product4.imageSrc" :alt="product4.imageAlt" class="object-cover object-center" />
            </div>
          </div>
        </div>
      </div>
      
    </div>

  

  </div>
  


  <div class="bg-indigo-100">
    <div class="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8">
      <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">Learn how we can help you?<br />We’ll connect you with a AgencyMR expert!</h2>
      <div class="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
        <a href="/contact" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Get started</a>
        <a href="/Enterprise_Solutions" class="text-sm font-semibold leading-6 text-gray-900">Learn more <span aria-hidden="true">→</span></a>
      </div>
    </div>
  </div>


</template>

<script setup>
import { StarIcon } from '@heroicons/vue/20/solid'

const product1 = {
  name: 'Transparency and Control at Your Fingertips.',
  version: { name: '1.0', date: 'June 5, 2021', datetime: '2021-06-05' },
  price: '$220',
  description:
    'AgencyMR offers full transparency on data sources and costs, allowing you to manage your research projects with confidence and clarity. Our system is designed to optimize your budget while ensuring high-quality results.',
  highlights: [
    '- Detailed Cost Breakdown: Easily manage your research costs and optimize budget allocations.',
    '- Real-Time Project Tracking: Stay updated with live data to adjust your strategies and maximize your research outcomes.',
  ],
  imageSrc: require('@/assets/images/z10.jpg'),
  imageAlt: '',
}

const reviews1 = {
  average: 5,
  featured: [
    {
      id: 1,
      rating: 5,
      content: `
        <p>This icon pack is just what I need for my latest project. There's an icon for just about anything I could ever need. Love the playful look!</p>
      `,
      date: 'July 16, 2021',
      datetime: '2021-07-16',
      author: 'Emily Selman',
      avatarSrc:
        'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
    },
  ],
}

const product2 = {
  name: 'Uncompromising Quality Standards.',
  version: { name: '2.0', date: 'August 20, 2024', datetime: '2024-08-20' },
  price: '$320',
  description:
    'Quality is at the heart of everything we do at AgencyMR. Every participant is thoroughly vetted through our state-of-the-art verification process, ensuring that you receive reliable and high-quality data for your research needs.',
  highlights: [
    '- In-Depth Data Verification',
    '- Behavioral Analysis',
    '- Advanced AI Screening',
  ],
  imageSrc: require('@/assets/images/z11.jpg'),
  imageAlt: '',
}

const reviews2 = {
  average: 4.5,
  featured: [
    {
      id: 1,
      rating: 4,
      content: `
        <p>Great product for advanced users. The machine learning models are top-notch, but the interface could be more intuitive.</p>
      `,
      date: 'August 25, 2024',
      datetime: '2024-08-25',
      author: 'John Doe',
      avatarSrc:
        'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
    },
  ],
}

const product3 = {
  name: 'No Surprises, Just Clear Pricing.',
  version: { name: '2.0', date: 'August 20, 2024', datetime: '2024-08-20' },
  price: '$320',
  description:
    'At AgencyMR, we believe in simplicity and transparency. Our platform offers straightforward pricing with no hidden fees, no long-term contracts, and no minimum project requirements, making it easier for you to focus on what matters most—your research.',
  highlights: [
    '- No Hidden Fees',
    '- Flexible Terms',
    '- Pay for What You Use',
  ],
  imageSrc: require('@/assets/images/z12.jpg'),
  imageAlt: '',
}

const reviews3 = {
  average: 4.5,
  featured: [
    {
      id: 1,
      rating: 4,
      content: `
        <p>Great product for advanced users. The machine learning models are top-notch, but the interface could be more intuitive.</p>
      `,
      date: 'August 25, 2024',
      datetime: '2024-08-25',
      author: 'John Doe',
      avatarSrc:
        'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
    },
  ],
}


const product4 = {
  name: 'Streamlined Project Management.',
  version: { name: '2.0', date: 'August 20, 2024', datetime: '2024-08-20' },
  price: '$320',
  description:
    'Managing your research projects is straightforward with AgencyMR. Our intuitive platform allows you to quickly set up surveys, manage participant quotas, and adjust your strategy in real-time, ensuring your research stays on track.',
  highlights: [
    '- Quick Setup: Easily launch projects and access global audiences in just a few steps.',
    '- Real-Time Monitoring: Make data-driven decisions on the fly with our live tracking features.',
    '- Instant Insights: Access the data you need, when you need it, to keep your research projects moving forward.',
  ],
  imageSrc: require('@/assets/images/z13.jpg'),
  imageAlt: '',
}

const reviews4 = {
  average: 4.5,
  featured: [
    {
      id: 1,
      rating: 4,
      content: `
        <p>Great product for advanced users. The machine learning models are top-notch, but the interface could be more intuitive.</p>
      `,
      date: 'August 25, 2024',
      datetime: '2024-08-25',
      author: 'John Doe',
      avatarSrc:
        'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
    },
  ],
}
</script>

<style>
</style>
