
<template>
  <div class="mx-auto px-4 py-16 sm:px-6 sm:pt-36 pb-24 lg:max-w-7xl lg:px-8">
    <div class="relative bg-white">
      <div class="lg:absolute lg:inset-0 lg:left-1/2">
        <img class="h-64 w-full bg-gray-50 object-cover sm:h-80 lg:absolute lg:h-full" src="https://images.unsplash.com/photo-1559136555-9303baea8ebd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-x=.4&w=2560&h=3413&&q=80" alt="" />
      </div>
      <div class="pb-24 pt-16 sm:pb-32 sm:pt-24 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:pt-32">
        <div class="px-6 lg:px-8">
          <div class="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
            <h2 class="text-3xl font-bold tracking-tight text-gray-900">Let's work together !</h2>
            <p class="mt-2 text-lg leading-8 text-gray-600">Whether you’re a current customer or just interested in learning more, we’d love to hear from you. Reach out today!</p>
            <form @submit.prevent="submitForm" method="POST" class="mt-16">
              <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                <div>
                  <label for="first_name" class="block text-sm font-semibold leading-6 text-gray-900">First name (required)</label>
                  <div class="mt-2.5">
                    <input type="text" name="first_name" id="first_name" v-model="formData.first_name" autocomplete="given-name" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" required  />
                  </div>
                </div>
                <div>
                  <label for="last_name" class="block text-sm font-semibold leading-6 text-gray-900">Last name (required)</label>
                  <div class="mt-2.5">
                    <input type="text" name="last_name" id="last_name" v-model="formData.last_name" autocomplete="family-name" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" required  />
                  </div>
                </div>
                <div class="sm:col-span-2">
                  <label for="email" class="block text-sm font-semibold leading-6 text-gray-900">Email (required)</label>
                  <div class="mt-2.5">
                    <input id="email" name="email" type="email" v-model="formData.email" autocomplete="email" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" required  />
                  </div>
                </div>
                <div class="sm:col-span-2">
                  <label for="company" class="block text-sm font-semibold leading-6 text-gray-900">Company (required)</label>
                  <div class="mt-2.5">
                    <input type="text" name="company" id="company" v-model="formData.company" autocomplete="organization" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" required  />
                  </div>
                </div>
                <div class="sm:col-span-2">
                <label for="source" class="block text-sm font-semibold leading-6 text-gray-900">How did you hear about us? (required)</label>
                <div class="mt-2.5">
                  <select name="source" id="source" v-model="formData.source"  class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" required >
                    <option value="" disabled selected>Choose</option>
                    <option value="Online Search">Online Search</option>
                    <option value="Conference">Conference</option>
                    <option value="Referral">Referral</option>
                    <option value="Social Media">Social Media</option>
                    <option value="AgencyMR Representative">AgencyMR Representative</option>
                    <option value="Academic Publication">Academic Publication</option>
                    <option value="News">News</option>
                    <option value="Other">Other</option>
                    <option value="Advertising">Advertising</option>
                  </select>
                </div>
              </div>
  
                <div class="sm:col-span-2">
                  <div class="flex justify-between text-sm leading-6">
                    <label for="phone" class="block font-semibold text-gray-900">Phone</label>
                    <p id="phone-description" class="text-gray-400">Optional</p>
                  </div>
                  <div class="mt-2.5">
                    <input type="tel" name="phone" id="phone" v-model="formData.phone" autocomplete="tel" aria-describedby="phone-description" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                  </div>
                </div>
                <div class="sm:col-span-2">
                  <div class="flex justify-between text-sm leading-6">
                    <label for="message" class="block text-sm font-semibold leading-6 text-gray-900">Message (required)</label>
                    <p id="message-description" class="text-gray-400">Max 500 characters</p>
                  </div>
                  <div class="mt-2.5">
                    <textarea id="message" name="message" rows="6" v-model="formData.message" aria-describedby="message-description" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" required ></textarea>
                  </div>
                </div>
                <fieldset class="sm:col-span-2">
                <legend class="block text-sm/6 font-semibold text-gray-900">I am interested in: (required)</legend>
                <div class="mt-4 space-y-4 text-sm/6 text-gray-600" required >
                  <div class="flex gap-x-2.5">
                    <input id="Become_a_Supplier_API_Monetization" v-model="formData.interested" name="interested" value="Become_a_Supplier_API_Monetization" type="radio" class="mt-1 h-4 w-4 border-gray-300 text-indigo-600 shadow-sm focus:ring-indigo-600" required >
                    <label for="Become_a_Supplier_API_Monetization">Become a Supplier/API Monetization </label>
                  </div>
                  <div class="flex gap-x-2.5">
                    <input id="B2B_Sample" name="interested" v-model="formData.interested" value="B2B_Sample" type="radio" class="mt-1 h-4 w-4 border-gray-300 text-indigo-600 shadow-sm focus:ring-indigo-600" required >
                    <label for="B2B_Sample">B2B Sample </label>
                  </div>
                  <div class="flex gap-x-2.5">
                    <input id="Consumer_Sample" name="interested" v-model="formData.interested" value="Consumer_Sample" type="radio" class="mt-1 h-4 w-4 border-gray-300 text-indigo-600 shadow-sm focus:ring-indigo-600" required >
                    <label for="Consumer_Sample">Consumer Sample </label>
                  </div>
                  <div class="flex gap-x-2.5">
                    <input id="Healthcare_Sample" name="interested" v-model="formData.interested" value="Healthcare_Sample" type="radio" class="mt-1 h-4 w-4 border-gray-300 text-indigo-600 shadow-sm focus:ring-indigo-600" required >
                    <label for="Healthcare_Sample">Healthcare Sample</label>
                  </div>
                  <div class="flex gap-x-2.5">
                    <input id="Custom_Research_Solutions" name="interested" v-model="formData.interested" value="Custom_Research_Solutions" type="radio" class="mt-1 h-4 w-4 border-gray-300 text-indigo-600 shadow-sm focus:ring-indigo-600" required >
                    <label for="Custom_Research_Solutions">Custom Research Solutions </label>
                  </div>
                  <div class="flex gap-x-2.5">
                    <input id="Data_Analytics_Insights" name="interested" v-model="formData.interested" value="Data_Analytics_Insights" type="radio" class="mt-1 h-4 w-4 border-gray-300 text-indigo-600 shadow-sm focus:ring-indigo-600" required >
                    <label for="Data_Analytics_Insights">Data Analytics & Insights </label>
                  </div>
                  <div class="flex gap-x-2.5">
                    <input id="Survey_Technology_Solutions" name="interested"  v-model="formData.interested" value="Survey_Technology_Solutions" type="radio" class="mt-1 h-4 w-4 border-gray-300 text-indigo-600 shadow-sm focus:ring-indigo-600" required >
                    <label for="Survey_Technology_Solutions">Survey Technology Solutions </label>
                  </div>
                </div>
              </fieldset>
              </div>
              
              <p v-if="message" :class="messageType === 'success' ? 'text-green-600' : 'text-red-600'" class="mt-4 text-sm">
                {{ message }}
              </p>
              <div class="mt-10 flex justify-end border-t border-gray-900/10 pt-8">
                <button type="submit" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Send message</button>
              </div>

              
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
  <!-- 分界线 -->
  <div class="bg-white py-24 sm:py-32">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl space-y-16 divide-y divide-gray-100 lg:mx-0 lg:max-w-none">
          <div class="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
            <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-7 lg:gap-3">
              <div class="rounded-2xl bg-gray-50 p-10">
                <h3 class="text-base font-semibold leading-7 text-gray-900">Hong Kong (Headquarters)</h3>
                <dl class="mt-3 space-y-1 text-sm leading-6 text-gray-600">
                  <div>
                    <dt class="sr-only">Hong Kong (Headquarters)</dt>
                    <dd><a class="font-semibold text-indigo-600" href="mailto:info@agencymr.com ">info@agencymr.com </a></dd>
                  </div>
                  <div class="mt-1">
                    <dt class="sr-only">Hong Kong (Headquarters)</dt>
                    <dd>HO KING COMM CENTRE, FAYUEN STREET, MONG KOK HONG KONG</dd>
                  </div>
                </dl>
              </div>
              <div class="rounded-2xl bg-gray-50 p-10">
                <h3 class="text-base font-semibold leading-7 text-gray-900">United States office</h3>
                <dl class="mt-3 space-y-1 text-sm leading-6 text-gray-600">
                  <div>
                    <dt class="sr-only">United States office</dt>
                    <dd><a class="font-semibold text-indigo-600" href="mailto:info@agencymr.com ">info@agencymr.com </a></dd>
                  </div>
                  <div class="mt-1">
                    <dt class="sr-only">United States office</dt>
                    <dd>17800 Castleton St, City of Industry, CA 91748</dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
  
        </div>
      </div>
    </div>
  
    <div class="bg-indigo-100">
      <div class="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">Learn how we can help you?<br />We’ll connect you with a AgencyMR expert!</h2>
        <div class="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
          <a href="#" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Get started</a>
          <a href="#" class="text-sm font-semibold leading-6 text-gray-900">Learn more <span aria-hidden="true">→</span></a>
        </div>
      </div>
    </div>
  </template>
  

  
  <script>
  export default {
    data() {
      return {
        formData: {
          first_name: '',
          last_name: '',
          email: '',
          company: '',
          source: '',
          phone: '',
          message: '',
          interested: ''
        },
        message: '',          // Stores message content
        messageType: ''       // Indicates message type (success or error)
      };
    },
    methods: {
      async submitForm() {
        try {
          const response = await fetch('https://auth.agencymr.com/api/Contact/add', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.formData)
          });
  
          const result = await response.json();
          
          if (result.code === 1) {
            // Successful submission, redirect to Thank You page
            console.log('Navigating to Thank You page');
            this.$router.push({ name: 'Thank_You_Form_Fill' });
          } else {
            // Error submission message
            this.message = result.msg || 'There was an error submitting the form. Please try again.';
            this.messageType = 'error';
          }
        } catch (error) {
          this.message = 'There was an error submitting the form. Please check your connection and try again.';
          this.messageType = 'error';
          console.error('An error occurred:', error);
        }
      }
    }
  };
  </script>
  
  <style>
  
  </style>