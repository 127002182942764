
<template>
  <div class="bg-white py-24 mt-12 sm:py-32">
    <div class="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
      <h2 class="text-base/7 font-semibold text-indigo-400">Building an Efficient Sample Service Ecosystem</h2>
      <p class="mt-2 max-w-5xl text-pretty text-4xl font-semibold tracking-tight text-gray-700 white sm:text-5xl">Agencymr - The Preferred Platform Connecting Research Clients with Verified Survey Suppliers</p>
      <div class="mt-10 grid grid-cols-1 gap-4 sm:mt-16 lg:grid-cols-6 lg:grid-rows-2">
        <div class="flex p-px lg:col-span-4">
          <div class="overflow-hidden rounded-lg bg-gray-800 ring-1 ring-white/15 max-lg:rounded-t-[2rem] lg:rounded-tl-[2rem]">
            <img class="h-80 object-cover object-left" src="./assets/images/a11.jpg" width="800px" alt="" />
            <div class="p-10">
              <h3 class="text-sm/4 font-semibold text-gray-400">Releases</h3>
              <p class="mt-2 text-lg/7 font-medium tracking-tight text-white">Verified Survey Suppliers</p>
              <p class="mt-2 max-w-lg text-sm/6 text-gray-400">We rigorously vet the survey suppliers on our platform to ensure you receive high-quality, reliable services. This provides a solid foundation for your research projects.</p>
            </div>
          </div>
        </div>
        <div class="flex p-px lg:col-span-2">
          <div class="overflow-hidden rounded-lg bg-gray-800 ring-1 ring-white/15 lg:rounded-tr-[2rem]">
            <img class="h-80 object-cover object-center" src="./assets/images/a12.jpg" alt="" />
            <div class="p-10">
              <h3 class="text-sm/4 font-semibold text-gray-400">Integrations</h3>
              <p class="mt-2 text-lg/7 font-medium tracking-tight text-white">Real-Time Project Progress Tracking</p>
              <p class="mt-2 max-w-lg text-sm/6 text-gray-400">At Agencymr, you can view project specifications in real time, making adjustments to quotas and eligibility criteria without cumbersome communication. Our dynamic dashboard helps you easily manage sample sources and costs, ensuring smooth project progression.</p>
            </div>
          </div>
        </div>
        <div class="flex p-px lg:col-span-2">
          <div class="overflow-hidden rounded-lg bg-gray-800 ring-1 ring-white/15 lg:rounded-bl-[2rem]">
            <img class="h-80 object-cover object-center" src="https://tailwindui.starxg.com/plus/img/component-images/bento-02-security.png" alt="" />
            <div class="p-10">
              <h3 class="text-sm/4 font-semibold text-gray-400">Security</h3>
              <p class="mt-2 text-lg/7 font-medium tracking-tight text-white">Flexible Selection of Survey Suppliers</p>
              <p class="mt-2 max-w-lg text-sm/6 text-gray-400">Through our platform, research clients can freely choose from a list of verified survey suppliers and quickly assign survey tasks. They will be responsible for promoting and implementing the surveys, while you simply monitor the results.</p>
            </div>
          </div>
        </div>
        <div class="flex p-px lg:col-span-4">
          <div class="overflow-hidden rounded-lg bg-gray-800 ring-1 ring-white/15 max-lg:rounded-b-[2rem] lg:rounded-br-[2rem]">
            <img class="h-80 object-cover mb-5 object-left" src="./assets/images/a10.jpg" alt="" width="800px"/>
            <div class="p-10">
              <h3 class="text-sm/4 font-semibold text-gray-400">Performance</h3>
              <p class="mt-2 text-lg/7 font-medium tracking-tight text-white">Reachable Audiences</p>
              <p class="mt-2 max-w-lg text-sm/6 text-gray-400">At Agencymr, we offer a variety of audience options for your market research, ensuring you obtain the most relevant samples to meet your research needs.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>




<!-- 第二部分 -->
<div class="bg-gray-50 py-24 sm:py-32">
    <div class="mx-auto max-w-2xl mt-12 px-6 lg:max-w-7xl lg:px-8">
      <h2 class="text-center text-base/7 font-semibold text-indigo-600">Building an Efficient Sample Service Ecosystem</h2>
      <p class="mx-auto mt-2 max-w-5xl text-balance text-center text-4xl font-semibold tracking-tight text-gray-950 sm:text-5xl">Agencymr - The Preferred Platform Connecting Research Clients with Verified Survey Suppliers</p>
        <div class="mt-10 grid grid-cols-1 gap-4 sm:mt-16 lg:grid-cols-6  ">
        
        <div class="relative lg:col-span-2">
          <div class="absolute inset-px rounded-lg bg-white lg:rounded-bl-[2rem]" />
          <div class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-b-[calc(2rem+1px)] lg:rounded-br-[calc(2rem+1px)]">
            <img class="h-80 object-cover object-center" src="./assets/images/a8.jpg" alt="" />
            <div class="p-10 pt-4">
     
              <p class="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">B2C</p>
              <p class="mt-2 max-w-lg text-sm/6 text-gray-600">You can directly connect with the highest quality end consumers worldwide through our verified survey suppliers, gaining insights into their purchasing habits, brand preferences, and consumer psychology to capture market dynamics.</p>
            </div>
          </div>
          <div class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-bl-[2rem]" />
        </div>
        <div class="relative lg:col-span-2">
          <div class="absolute inset-px rounded-lg bg-white" />
          <div class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)]">
            <img class="h-80 object-cover object-center" src="./assets/images/a9.jpg" alt="" />
            <div class="p-10 pt-4">
    
              <p class="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">B2B</p>
              <p class="mt-2 max-w-lg text-sm/6 text-gray-600">Similarly, through our platform, you can reach decision-makers and executives across various industries, allowing your market research to delve into business needs, trends, and challenges. Agencymr can provide you with precise B2B audiences.</p>
            </div>
          </div>
          <div class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5" />
        </div>
        <div class="relative lg:col-span-2">
          <div class="absolute inset-px rounded-lg bg-white max-lg:rounded-b-[2rem] lg:rounded-br-[2rem]" />
          <div class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-b-[calc(2rem+1px)] lg:rounded-br-[calc(2rem+1px)]">
            <img class="h-80 object-cover object-center" src="./assets/images/a7.jpg" alt="" />
            <div class="p-10 pt-4">

              <p class="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">Healthcare</p>
              <p class="mt-2 max-w-lg text-sm/6 text-gray-600">If your research involves the healthcare field, our sampling services can help you connect with doctors, nurses, patients, and other healthcare professionals.</p>
            </div>
          </div>
          <div class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-b-[2rem] lg:rounded-br-[2rem]" />
        </div>
      </div>

    </div>
  </div>


  <!-- 第三部分 -->

  <!-- <div class="bg-white py-24 sm:py-32">
    <div class="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
      <h2 class="text-base/7 font-semibold text-indigo-400">Building an Efficient Sample Service Ecosystem</h2>
      <p class="mt-2 max-w-5xl text-pretty text-4xl font-semibold tracking-tight text-gray-700 white sm:text-5xl">Agencymr - The Preferred Platform Connecting Research Clients with Verified Survey Suppliers</p>
      <div class="mt-10 grid grid-cols-1 gap-4 sm:mt-16 lg:grid-cols-6 lg:grid-rows-2">
  
        <div class="relative lg:col-span-2">
          <div class="absolute inset-px rounded-lg bg-white lg:rounded-bl-[2rem]" />
          <div class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-bl-[calc(2rem+1px)]">
            <img class="h-80 object-cover object-left" src="https://tailwindui.starxg.com/plus/img/component-images/bento-01-speed.png" alt="" />
            <div class="p-10 pt-4">
              <h3 class="text-sm/4 font-semibold text-indigo-600">Speed</h3>
              <p class="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">Built for power users</p>
              <p class="mt-2 max-w-lg text-sm/6 text-gray-600">Sed congue eros non finibus molestie. Vestibulum euismod augue.</p>
            </div>
          </div>
          <div class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-bl-[2rem]" />
        </div>
        <div class="relative lg:col-span-2">
          <div class="absolute inset-px rounded-lg bg-white" />
          <div class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)]">
            <img class="h-80 object-cover object-center" src="https://tailwindui.starxg.com/plus/img/component-images/bento-01-integrations.png" alt="" />
            <div class="p-10 pt-4">
              <h3 class="text-sm/4 font-semibold text-indigo-600">Integrations</h3>
              <p class="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">Connect your favorite tools</p>
              <p class="mt-2 max-w-lg text-sm/6 text-gray-600">Maecenas at augue sed elit dictum vulputate, in nisi aliquam maximus arcu.</p>
            </div>
          </div>
          <div class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5" />
        </div>
        <div class="relative lg:col-span-2">
          <div class="absolute inset-px rounded-lg bg-white max-lg:rounded-b-[2rem] lg:rounded-br-[2rem]" />
          <div class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-b-[calc(2rem+1px)] lg:rounded-br-[calc(2rem+1px)]">
            <img class="h-80 object-cover object-center" src="https://tailwindui.starxg.com/plus/img/component-images/bento-01-network.png" alt="" />
            <div class="p-10 pt-4">
              <h3 class="text-sm/4 font-semibold text-indigo-600">Network</h3>
              <p class="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">Globally distributed CDN</p>
              <p class="mt-2 max-w-lg text-sm/6 text-gray-600">Aenean vulputate justo commodo auctor vehicula in malesuada semper.</p>
            </div>
          </div>
          <div class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-b-[2rem] lg:rounded-br-[2rem]" />
        </div>
      </div>
    </div>
  </div> -->


</template> 




<script setup>

</script>