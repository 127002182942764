<template>
  
 
  <section>
    <div class="bg-white py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl text-center my-16">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"  style="color: #0D2254;">AgencyMR Blog  </h2>
        <p class="mt-6  mb-24 text-md leading-8 text-gray-600">Library of the latest insights, press, resources, and trends in Market Research</p>
      </div>

      <div class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <article v-for="post in posts" :key="post.id" class="flex flex-col items-start justify-between opacity-0 transition-opacity duration-500" v-scroll-reveal>
          <div class="relative w-full">
            <img :src="post.imageUrl" alt="" class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]" />
            <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
          </div>
          <div class="max-w-xl">
            <div class="mt-8 flex items-center gap-x-4 text-xs">
              <time :datetime="post.datetime" class="text-gray-500">{{ post.date }}</time>
              <a :href="post.category.href" class="relative z-1 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100">{{ post.category.title }}</a>
            </div>
            <div class="group relative">
              <h3 class="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                <a :href="post.href">
                  <span class="absolute inset-0" />
                  {{ post.title }}
                </a>
              </h3>
              <p class="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{{ post.description }}</p>
            </div>
         
          </div>
        </article>
      </div>
    </div>
  </div>
  </section>
  <router-view />
  <div class="bg-indigo-100">
    <div class="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8">
      <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">Learn how we can help you?<br />We’ll connect you with a AgencyMR expert!</h2>
      <div class="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
        <a href="/contact" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Get started</a>
        <a href="/Enterprise_Solutions" class="text-sm font-semibold leading-6 text-gray-900">Learn more <span aria-hidden="true">→</span></a>
      </div>
    </div>
  </div>

</template>
<script setup>
const posts = [
  {
    id: 1,
    title: 'The best ways to gather consumer insights in the NEW normal?',
    href: '/Consumer_Insights',
    description:
      'Over the past few years, an overall boom in artificial intelligence & technology has changed consumer behavior and preferences at a rapid rate.',
    imageUrl:
      'https://images.pexels.com/photos/6231/marketing-color-colors-wheel.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    date: 'Oct 28, 2024',
    datetime: '2024-10-28',
    category: { title: 'CONSUMER INSIGHT', href: '/Consumer_Insights' },
  },
  {
    id: 2,
    title: 'Online Sample: Preventing Duplicate Respondents',
    href: '/Preventing_Duplicate_Respondents',
    description:
      'Ensuring that each respondent only takes your survey once is essential for maintaining data integrity. Duplicate responses can skew results for several reasons...',
    imageUrl:
      'https://www.color-meanings.com/wp-content/uploads/woman-colored-mirror-1024x682.jpeg',
    date: 'April 13, 2024',
    datetime: '2024-04-13',
    category: { title: 'DATA QUALITY', href: '/Preventing_Duplicate_Respondents' },
  },
  {
    id: 3,
    title: 'Online Survey Length – How Long is Too Long?',
    href: '/Online_Survey_Length',
    description:
      'If I had a dollar for every time a client asked me how long their survey should be, I’d be able to fund a week-long trip to Disney for my family of six.',
    imageUrl:
      'https://images.ctfassets.net/lzny33ho1g45/4Ql0NqhccLsD4OYL7BUaDZ/16340c449bc237a04c29e612dbb902b8/best_survey_apps.jpg',
    date: 'Oct 11, 2023',
    datetime: '2023-10-11',
    category: { title: 'DATA QUALITY', href: '/Online_Survey_Length' },
  },
  {
    id: 4,
    title: 'Fine Tuning Online Data Collection: Understanding Fraud Detection Settings',
    href: '/Understanding_Fraud_Detection_Settings',
    description:
      'Fine-Tuning Online Data Collection: Navigating Fraud Detection Software Simply informing clients that fraud detection software is in place is not enough. A solid understanding of the software is crucial to maintain the integrity of your data collection efforts.',
    imageUrl:
      'https://blog.cloudflare.com/content/images/2023/03/image1-23.png',
    date: 'Dec 06, 2023',
    datetime: '2023-12-06',
    category: { title: 'DATA QUALITY', href: '/Understanding_Fraud_Detection_Settings' },
  },
  
]
</script>

<style>

</style>