
<template>
  <div class="bg-white py-24 sm:py-32">
    <div class="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
      <h2 class="text-base/7 font-semibold text-indigo-600">Project Management</h2>
      <p class="mt-2 max-w-5xl text-pretty text-4xl font-semibold tracking-tight text-gray-950 sm:text-5xl">Effortlessly Control Your Research Projects</p>
      <p class="mt-4 text-lg text-gray-600">We are dedicated to providing efficient tools and features to help you comprehensively manage and monitor the progress <br> of your research projects.No matter the scale of your project, you'll find the support and resources you need here.</p>
      <div class="mt-10 grid grid-cols-1 gap-4 sm:mt-16 lg:grid-cols-6 lg:grid-rows-2">
        <div class="relative lg:col-span-3">
          <div class="absolute inset-px rounded-lg bg-white max-lg:rounded-t-[2rem] lg:rounded-tl-[2rem]" />
          <div class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-t-[calc(2rem+1px)] lg:rounded-tl-[calc(2rem+1px)]">
            <img class="h-80 object-cover object-left" src="https://tailwindui.starxg.com/plus/img/component-images/bento-01-performance.png" alt="" />
            <div class="p-10 pt-4">
              <h3 class="text-sm/4 font-semibold text-indigo-600">Performance</h3>
              <p class="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">Centralized Management Dashboard</p>
              <p class="mt-2 max-w-lg text-sm/6 text-gray-600">An intuitive dashboard allows you to view the status and progress of all your projects in real time, providing insights into key data to ensure each project moves forward effectively.</p>
            </div>
          </div>
          <div class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-t-[2rem] lg:rounded-tl-[2rem]" />
        </div>
        <div class="relative lg:col-span-3">
          <div class="absolute inset-px rounded-lg bg-white lg:rounded-tr-[2rem]" />
          <div class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-tr-[calc(2rem+1px)]">
            <img class="h-80 object-cover object-left lg:object-right" src="https://tailwindui.starxg.com/plus/img/component-images/bento-01-releases.png" alt="" />
            <div class="p-10 pt-4">
              <h3 class="text-sm/4 font-semibold text-indigo-600">Releases</h3>
              <p class="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">Task Assignment and Tracking</p>
              <p class="mt-2 max-w-lg text-sm/6 text-gray-600">Easily assign tasks to team members or survey suppliers, set deadlines, and track progress in real time to ensure all work is completed on time.</p>
            </div>
          </div>
          <div class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-tr-[2rem]" />
        </div>
        <div class="relative lg:col-span-3">
          <div class="absolute inset-px rounded-lg bg-white max-lg:rounded-t-[2rem] lg:rounded-tl-[2rem]" />
          <div class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-t-[calc(2rem+1px)] lg:rounded-tl-[calc(2rem+1px)]">
            <img class="h-80 object-cover object-left" src="https://tailwindui.starxg.com/plus/img/component-images/bento-01-performance.png" alt="" />
            <div class="p-10 pt-4">
              <h3 class="text-sm/4 font-semibold text-indigo-600">Performance</h3>
              <p class="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">Progress Reports and Analysis</p>
              <p class="mt-2 max-w-lg text-sm/6 text-gray-600">Generate detailed project progress reports that help you analyze the performance of each phase, identify potential issues, and adjust strategies promptly to optimize project outcomes.</p>
            </div>
          </div>
          <div class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-t-[2rem] lg:rounded-tl-[2rem]" />
        </div>
        <div class="relative lg:col-span-3">
          <div class="absolute inset-px rounded-lg bg-white lg:rounded-tr-[2rem]" />
          <div class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-tr-[calc(2rem+1px)]">
            <img class="h-80 object-cover object-left lg:object-right" src="https://tailwindui.starxg.com/plus/img/component-images/bento-01-releases.png" alt="" />
            <div class="p-10 pt-4">
              <h3 class="text-sm/4 font-semibold text-indigo-600">Releases</h3>
              <p class="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">Instant Notifications and Reminders</p>
              <p class="mt-2 max-w-lg text-sm/6 text-gray-600">With system notifications, you won’t miss any important deadlines or key updates, ensuring you stay at the forefront of your projects.</p>
            </div>
          </div>
          <div class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-tr-[2rem]" />
        </div>
      </div>
    </div>
  </div>

  <div class="bg-white pb-16 sm:py-24">
    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="relative isolate overflow-hidden bg-gray-900 px-6 py-24 shadow-2xl sm:rounded-3xl sm:px-24 xl:py-32">
        <h2 class="mx-auto max-w-2xl text-center text-4xl font-semibold tracking-tight text-white sm:text-5xl">Make Your Research Projects More Efficient</h2>
        <p class="mx-auto mt-6 max-w-4xl text-center text-lg text-gray-300">Whether you are an independent researcher, a market research team, or a large enterprise, Agencymr’s project management tools will help streamline processes and enhance efficiency. Start utilizing our project management features now to ensure every research project you undertake leads to success!</p>
        <form class="mx-auto mt-10 flex max-w-md gap-x-4 justify-center">
          <label for="email-address" class="sr-only">Email address</label>
          <a href="./login" class="flex-none rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
            Notify me
          </a>
        </form>
        <svg viewBox="0 0 1024 1024" class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2" aria-hidden="true">
          <circle cx="512" cy="512" r="512" fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fill-opacity="0.7" />
          <defs>
            <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(512 512) rotate(90) scale(512)">
              <stop stop-color="#7775D6" />
              <stop offset="1" stop-color="#E935C1" stop-opacity="0" />
            </radialGradient>
          </defs>
        </svg>
      </div>
    </div>
  </div>
</template>



<script setup>

</script>