<template>
  
 
  <section>
  <div class="bg-white py-32 sm:py-64">
    <div class="mx-auto max-w-7xl px-6 text-left lg:px-8">
      <div class="mx-auto">
        <h1 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl ">Do Not Sell My Information</h1>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900  mt-12 ">1.SERVICES AND SUPPORT</h2>
        <p class="mt-4 text-lg leading-8 text-gray-600 font-serif"> At AgencyMR, we are committed to protecting your privacy and ensuring that your personal information is handled in compliance with all relevant privacy laws and regulations, including the California Consumer Privacy Act (CCPA) and the General Data Protection Regulation (GDPR).</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif"> Under these laws, you have the right to request that we do not sell your personal information. We respect your choice and offer you the ability to exercise this right easily. By submitting a request through our designated channels, you can ensure that your information remains secure and is not shared for any sales purposes.</p>
        <p class="mt-8 text-lg leading-8 text-gray-600 font-serif"> If you have any questions or need further assistance with your privacy rights, please contact us at privacy@agencymr.com. We are here to help and ensure that your privacy preferences are honored.</p>
      </div>
  
    </div>
  </div>
  </section>
  <router-view />

</template>

<script>

</script>

<style>

</style>